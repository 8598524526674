import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewRef } from '@angular/core';
import { EditTicketModalComponent } from './edit-ticket-modal/edit-ticket-modal.component';
import { ResolveTicketModalComponent } from './resolve-ticket-modal/resolve-ticket-modal.component';
import _ from 'lodash';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GenericColumnsManagingModalComponent } from '../generic-columns-managing-modal/generic-columns-managing-modal.component';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { AddTicketModalComponent } from './add-ticket-modal/add-ticket-modal.component';
import moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { TranslatorService } from '../../services/translator_service';
import { UtilService } from '../../services/util.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ApiStore } from '../../web-services/api/api.store';
import { ApiService } from '../../web-services/api/api.service';
import { ResponsiveService } from '../../services/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiQuery } from '../../web-services/api/api.query';
import { Papa } from 'ngx-papaparse';
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { ViewTicketModalComponent } from './view-ticket-modal/view-ticket-modal.component';
import { TableUtil } from '../../tableUtil';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { PaginationComponent } from '../pagination/pagination.component';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { DatepickerRangeComponent } from '../../widgets/datepicker-range/datepicker-range.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketManagementMapComponent } from './ticket-management-map/ticket-management-map.component';

@Component({
  selector: 'app-ticket-management',
  standalone: true,
  imports: [TranslateModule,MatCardModule,CommonModule,MatTabsModule,PaginationComponent
    ,RTLDivDirectiveDirective,MatTableModule,MatSortModule,MatDividerModule,DatepickerRangeComponent,
	NgbModule,TicketManagementMapComponent,RouterModule],
  templateUrl: './ticket-management.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
      ])
    ],
  styleUrl: './ticket-management.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketManagementComponent {
  expandedElement: any;
  @ViewChild(MatSort,{static: false}) sort: MatSort;
	usageType = 1;
	currentLang;
	translationsObj;
  	isMobile: Boolean;
  	showDatePicker: Boolean = false;
	minDate = '';
  	maxDate = '';
	lastMaxDate:any;
	lastMinDate:any;

	checkOrReplacementTicketsArr:Array<object> = [];
	checkOrReplacementTicketsTableLength = 0;
	checkOrReplacementDataSource = new MatTableDataSource<any>();
	tempCheckOrReplacementFilter:any = '';
	startIndexTableDataCheckOrReplacement = 0;
	endIndexTableDataCheckOrReplacement = 9;
	displayedCheckOrReplacementColumns = ['ticket_id','ticket_status','event_cause','priority','site_name','bin_name','bin_address','comment','reporter','created','arrival_date','closed','manage'];
	checkOrReplacementColumnsToDisplay = ['ticket_status', 'event_cause','arrival_date', 'arrowDown'];
	checkOrReplacementSpinnerActive:boolean = true;
	filteredCheckOrReplacementData:Array<object> = [];
	mapCheckOrReplacementExpanded:boolean = false;

	installation:Array<object> = [];
	installationTicketsTableLength = 0;
	installationDataSource = new MatTableDataSource<any>();
	tempInstallationFilter:any = '';
	startIndexTableDataInstallation = 0;
	endIndexTableDataInstallation = 9;
	displayedInstallationColumns = ['ticket_id','ticket_status','event_cause','priority','site_name','bin_name','bin_address','comment','reporter','created','arrival_date','closed','manage'];
	installationColumnsToDisplay = ['ticket_status', 'event_cause','arrival_date', 'arrowDown'];
	installationSpinnerActive:boolean = true;
	filteredInstallationData:Array<object> = [];
	mapInstallationExpanded:boolean = false;

	cancellation:Array<object> = [];
	cancellationTicketsTableLength = 0;
	cancellationDataSource = new MatTableDataSource<any>();
	tempCancellationFilter:any = '';
	startIndexTableDataCancellation = 0;
	endIndexTableDataCancellation = 9;
	displayedCancellationColumns = ['ticket_id','ticket_status','event_cause','priority','site_name','bin_name','bin_address','comment','reporter','created','arrival_date','closed','removedReason','manage'];
	cancellationColumnsToDisplay = ['ticket_status', 'event_cause','arrival_date', 'arrowDown'];
	cancellationSpinnerActive:boolean = true;
	filteredCancellationData:Array<object> = [];
	mapCancellationExpanded:boolean = false;

	private readonly destroy$ = new Subject();
	routeParamsSub:any;
	ticketId:any;
	selectedDates: any = {
		from: '',
		to: ''
	};
	user_role_id = 0;
	user_id = 0;
	selectedTab:number = 0;
	openedTickets:Array<object> = [];
	closedTickets:Array<object> = [];
	markerSelected:object = {};
	eventAdded:boolean = false;
	expandedElementTable: object = {};

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private dialog: MatDialog,
	private apiService:ApiService,private apiStore:ApiStore,private apiQuery:ApiQuery,private utilService: UtilService,
	private papa: Papa,private route: ActivatedRoute,private cd: ChangeDetectorRef) { 
  }
  
  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();

	this.apiQuery.user$.subscribe(user => {
		if (!user) {
			return;
		}		
		if(user["usage_type_id"] != 1){
			this.usageType = user["usage_type_id"];
		}	
		this.user_role_id = user["user_role_id"];
		this.user_id = user["user_id"];
	});   

	this.translator.currentLangEmitter$
	.subscribe(async value=>{						
	  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
	  this.currentLang = value;	
	  this.routeParamsSub = this.route
	  .queryParams
	  .subscribe(params => {
		  this.ticketId = this.route.snapshot.paramMap.get("id");
		  combineLatest(
			  this.apiQuery.cRTicketsList$,
			  this.apiQuery.insTicketsList$,
			  this.apiQuery.rmTicketsList$,
			  this.apiQuery.filteredBasicBins$
			  )
			  .pipe(takeUntil(this.destroy$))
			  .subscribe(([cRTicketsList,insTicketsList,rmTicketsList,bins])=>{	
				  let checkOrReplacementTickets = [];	
				  let installationTickets = [];
				  let cancellationTickets = [];
  
				  this.openedTickets = [];
				  this.closedTickets = [];
  
				  this.checkOrReplacementSpinnerActive = true;
				  this.installationSpinnerActive = true;
				  this.cancellationSpinnerActive = true;
				  if(cRTicketsList.length == 0 && insTicketsList.length == 0 && rmTicketsList.length == 0){return;}
				  if(cRTicketsList.length > 0){this.checkOrReplacementSpinnerActive = false;}
				  if(insTicketsList.length > 0){this.installationSpinnerActive = false;}
				  if(rmTicketsList.length > 0){this.cancellationSpinnerActive = false;}
  
				  _.each(cRTicketsList, (item) => {					
					  if(item["TicketsList"]){
						  if(item["TicketsList"]["open"]!=null){			
							  _.each(item["TicketsList"]["open"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 1;
								  this.getRelevantBin(bins,ticket,"1");
								  checkOrReplacementTickets.push(ticket);		
							  });		
						  }
						  if(item["TicketsList"]["close"]!=null){			
							  _.each(item["TicketsList"]["close"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 4;
								  this.getRelevantBin(bins,ticket,"4");
								  checkOrReplacementTickets.push(ticket);		
							  });		
						  }		
					  }		
				  });
  
				  _.each(insTicketsList, (item) => {					
					  if(item["TicketsList"]){
						  if(item["TicketsList"]["open"]!=null){			
							  _.each(item["TicketsList"]["open"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 1;
								  this.getRelevantBin(bins,ticket,"1");
								  installationTickets.push(ticket);	
							  });		
						  }
						  if(item["TicketsList"]["close"]!=null){
							  _.each(item["TicketsList"]["close"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 4;
								  this.getRelevantBin(bins,ticket,"4");
								  installationTickets.push(ticket);	
							  });	
						  }									
					  }		
				  });
  
				  _.each(rmTicketsList, (item) => {					
					  if(item["TicketsList"]){
						  if(item["TicketsList"]["open"]!=null){			
							  _.each(item["TicketsList"]["open"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 1;
								  this.getRelevantBin(bins,ticket,"1");
								  cancellationTickets.push(ticket);	
							  });		
						  }
						  if(item["TicketsList"]["close"]!=null){
							  _.each(item["TicketsList"]["close"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 4;
								  this.getRelevantBin(bins,ticket,"4");
								  cancellationTickets.push(ticket);	
							  });	
						  }									
					  }		
				  });

				  const checkOrReplacementTicketsFiltered = _.map(checkOrReplacementTickets, ticket => {
					  return {
						  ticket_id : ticket["ticket_id"],
						  status_txt : ticket["status"] == 1 ? this.translationsObj.TICKET_MANAGEMENT.OPENED : this.translationsObj.TICKET_MANAGEMENT.CLOSED_STATUS,
						  eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
						  priorityEn : ticket["ticketData"].priority_name,
						  site_name : ticket["site_name"],
						  bin_name : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_name) : ''),
						  bin_address : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_address) : ''),
						  comment : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].comment : '',
						  reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name != null && _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name != null ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name : this.translationsObj.TICKET_MANAGEMENT.AUTOMATIC_TICKET) : '',
						  created : ticket["ticket_id_info"].length > 0 ? this.utilService.convertDateEnglishWithoutTime(ticket["ticket_id_info"][0].action_time) : '',
						  expected_arrival_date : this.utilService.convertDateEnglishWithoutTime(ticket["ticketData"].expected_arrival_date == '0000-00-00 00:00:00' ? (ticket["ticketData"].priority_id == 3 ? moment().add(1,'weeks').format('YYYY-MM-DD HH:mm:ss') : moment().add(2,'weeks').format('YYYY-MM-DD HH:mm:ss')) : moment(ticket["ticketData"].expected_arrival_date).format('YYYY-MM-DD HH:mm:ss')),
						  closed : this.utilService.convertDateEnglish(ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '')),
						  resolvedDescription : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment : '',
						  eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
						  priorityHe : ticket["ticketData"].priority_name_hebrew,
						  site_id : ticket["site_id"],
						  ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
						  priority_id : ticket["ticketData"].priority_id,
						  ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
						  ticketInfo : ticket["ticket_id_info"],
						  pic_url : ticket["ticketData"].pic_url,
						  first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
						  last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  user_id : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].user_id: '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : ''),
						  bin_id : ticket["bin_id"],
						  eventTypeEn : ticket["ticketData"].ticket_event_type_name,
						  eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
						  status : ticket["status"],
						  created_he : ticket["ticket_id_info"].length > 0 ? this.utilService.convertDateHebrewWithoutTime(ticket["ticket_id_info"][0].action_time):'',
						  expected_arrival_date_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticketData"].expected_arrival_date == '0000-00-00 00:00:00' ? (ticket["ticketData"].priority_id == 3 ? moment().add(1,'weeks').format('YYYY-MM-DD HH:mm:ss') : moment().add(2,'weeks').format('YYYY-MM-DD HH:mm:ss')) : moment(ticket["ticketData"].expected_arrival_date).format('YYYY-MM-DD HH:mm:ss')),
						  closed_he : this.utilService.convertDateHebrew(ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '')),
						  created_DateTime_value : ticket["ticket_id_info"].length > 0 ? moment(ticket["ticket_id_info"][0].action_time).format("YYYY-MM-DD HH:mm:ss").valueOf() : '',
						  closed_DateTime_value : ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : ''),
						  created_convertDateEnglish : ticket["ticket_id_info"].length > 0 ? this.utilService.convertDateEnglish(ticket["ticket_id_info"][0].action_time) : '',
						  created_convertDateHebrew : ticket["ticket_id_info"].length > 0 ? this.utilService.convertDateHebrew(ticket["ticket_id_info"][0].action_time) : '',
						  includedInWorkplan : ticket.includedInWorkplan
					  };
				  });	
		  
				  const installationTicketsFiltered = _.map(installationTickets, ticket => {
					  return {
						  ticket_id : ticket["ticket_id"],
						  status_txt : ticket["status"] == 1 ? this.translationsObj.TICKET_MANAGEMENT.OPENED : this.translationsObj.TICKET_MANAGEMENT.CLOSED_STATUS,
						  eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
						  priorityEn : ticket["ticketData"].priority_name,
						  site_name : ticket["site_name"],
						  bin_name : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_name) : ''),
						  bin_address : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_address) : ''),
						  comment : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].comment : '',
						  reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: this.translationsObj.TICKET_MANAGEMENT.AUTOMATIC_TICKET,
						  created : this.utilService.convertDateEnglishWithoutTime(ticket["ticket_id_info"][0].action_time),
						  expected_arrival_date : this.utilService.convertDateEnglishWithoutTime(ticket["ticketData"].expected_arrival_date),
						  closed : this.utilService.convertDateEnglish(ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '')),
						  resolvedDescription : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment : '',
						  eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
						  priorityHe : ticket["ticketData"].priority_name_hebrew,
						  site_id : ticket["site_id"],
						  ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
						  priority_id : ticket["ticketData"].priority_id,
						  ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
						  ticketInfo : ticket["ticket_id_info"],
						  pic_url : ticket["ticketData"].pic_url,
						  first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
						  last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  user_id : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].user_id: '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : ''),
						  bin_id : ticket["bin_id"],
						  eventTypeEn : ticket["ticketData"].ticket_event_type_name,
						  eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
						  status : ticket["status"],
						  created_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticket_id_info"][0].action_time),
						  expected_arrival_date_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticketData"].expected_arrival_date),
						  closed_he : this.utilService.convertDateHebrew(ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '')),
						  created_DateTime_value : moment(ticket["ticket_id_info"][0].action_time).format("YYYY-MM-DD HH:mm:ss").valueOf(),
						  closed_DateTime_value : ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : ''),
						  created_convertDateEnglish : this.utilService.convertDateEnglish(ticket["ticket_id_info"][0].action_time),
						  created_convertDateHebrew : this.utilService.convertDateHebrew(ticket["ticket_id_info"][0].action_time)
					  };
				  });	
				  
				  const cancellationTicketsFiltered = _.map(cancellationTickets, ticket => {
					  return {
						  ticket_id : ticket["ticket_id"],
						  status_txt : ticket["status"] == 1 ? this.translationsObj.TICKET_MANAGEMENT.OPENED : this.translationsObj.TICKET_MANAGEMENT.CLOSED_STATUS,
						  eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
						  priorityEn : ticket["ticketData"].priority_name,
						  site_name : ticket["site_name"],
						  bin_name : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_name) : ''),
						  bin_address : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].bin_address) : ''),
						  comment : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].comment : '',
						  reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: this.translationsObj.TICKET_MANAGEMENT.AUTOMATIC_TICKET,
						  created : this.utilService.convertDateEnglishWithoutTime(ticket["ticket_id_info"][0].action_time),
						  expected_arrival_date : this.utilService.convertDateEnglishWithoutTime(ticket["ticketData"].expected_arrival_date),
						  closed : this.utilService.convertDateEnglish(ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '')),
						  reasonOfReplacementEn : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")[4][_.groupBy(ticket["ticket_id_info"], "status_id")[4].length - 1].ticket_remove_replace_cause_name : '',
						  reasonOfReplacementHe : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")[4][_.groupBy(ticket["ticket_id_info"], "status_id")[4].length - 1].ticket_remove_replace_cause_name_hebrew : '', 
						  resolvedDescription : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment : '',
						  eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
						  priorityHe : ticket["ticketData"].priority_name_hebrew,
						  site_id : ticket["site_id"],
						  ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
						  priority_id : ticket["ticketData"].priority_id,
						  ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
						  ticketInfo : ticket["ticket_id_info"],
						  pic_url : ticket["ticketData"].pic_url,
						  first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
						  last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
						  user_id : ticket["status"] == 1 ? (_.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].user_id: '') : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : ''),
						  bin_id : ticket["bin_id"],
						  eventTypeEn : ticket["ticketData"].ticket_event_type_name,
						  eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
						  status : ticket["status"],
						  created_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticket_id_info"][0].action_time),
						  expected_arrival_date_he : this.utilService.convertDateHebrewWithoutTime(ticket["ticketData"].expected_arrival_date),
						  closed_he : this.utilService.convertDateHebrew(ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '')),
						  created_DateTime_value : moment(ticket["ticket_id_info"][0].action_time).format("YYYY-MM-DD HH:mm:ss").valueOf(),
						  closed_DateTime_value : ticket["status"] == 1 ? '' : (_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : ''),
						  created_convertDateEnglish : this.utilService.convertDateEnglish(ticket["ticket_id_info"][0].action_time),
						  created_convertDateHebrew : this.utilService.convertDateHebrew(ticket["ticket_id_info"][0].action_time)
					  };
				  });	
		  
				  this.checkOrReplacementTicketsArr = checkOrReplacementTicketsFiltered;			
				  this.checkOrReplacementTicketsTableLength = this.checkOrReplacementTicketsArr.length;
				  this.checkOrReplacementDataSource = new MatTableDataSource<any>(checkOrReplacementTicketsFiltered);
				  this.checkOrReplacementDataSource.sort = this.sort;
		  
				  this.installation = installationTicketsFiltered;			
				  this.installationTicketsTableLength = this.installation.length;
				  this.installationDataSource = new MatTableDataSource<any>(installationTicketsFiltered);
				  this.installationDataSource.sort = this.sort;
  
				  this.cancellation = cancellationTicketsFiltered;			
				  this.cancellationTicketsTableLength = this.cancellation.length;
				  this.cancellationDataSource = new MatTableDataSource<any>(cancellationTicketsFiltered);
				  this.cancellationDataSource.sort = this.sort;

				 // if(!this.eventAdded){
					this.sortCheckOrReplacementTicketsData({ direction: 'desc', active: 'created_DateTime_value' });
					this.sortCheckOrReplacementTicketsData({ direction: 'asc', active: 'status' });
					this.sortInstallationTicketsData({ direction: 'desc', active: 'created_DateTime_value' });
					this.sortInstallationTicketsData({ direction: 'asc', active: 'status' });
					this.sortCancellationTicketsData({ direction: 'desc', active: 'created_DateTime_value' });
					this.sortCancellationTicketsData({ direction: 'asc', active: 'status' });
				  //}
				//   else{
				// 	if(this.selectedTab == 0){
				// 		this.sortCheckOrReplacementTicketsData({ direction: 'desc', active: 'created_DateTime_value' });
				// 	}else if(this.selectedTab == 1){
				// 		this.sortInstallationTicketsData({ direction: 'desc', active: 'created_DateTime_value' });
				// 	}else if(this.selectedTab == 2){
				// 		this.sortCancellationTicketsData({ direction: 'desc', active: 'created_DateTime_value' });
				// 	}
				//   }
  
				  this.openedTickets = this.openedTickets.concat(_.groupBy(this.checkOrReplacementTicketsArr, "status")["1"],_.groupBy(this.installation, "status")["1"],_.groupBy(this.cancellation, "status")["1"]);
				  this.closedTickets = this.closedTickets.concat(_.groupBy(this.checkOrReplacementTicketsArr, "status")["4"],_.groupBy(this.installation, "status")["4"],_.groupBy(this.cancellation, "status")["4"]);
  
				  if(!_.isEmpty(this.apiStore.getValue()["selectedTicket"]) && this.openedTickets.length > 0){			
					const relevantTicket = _.filter(this.openedTickets, (res) => {   
					  if(res){
						  return res["ticket_id"] == this.apiStore.getValue()["selectedTicket"]["ticket_id"];
					  }   
					}); 	
					  this.dialog.closeAll();
					  if(relevantTicket.length > 0){
						if(relevantTicket[0]["ticket_event_type_id"] == 1){
							this.resolveAction(relevantTicket[0],1,0);
						}else if(relevantTicket[0]["ticket_event_type_id"] == 3){
							this.resolveAction(relevantTicket[0],1,1);
						}else if(relevantTicket[0]["ticket_event_type_id"] == 4){
							this.resolveAction(relevantTicket[0],1,2);
						}
					  }
				  }	
  
				  if(this.ticketId && cRTicketsList.length != 0 && insTicketsList.length != 0 && rmTicketsList.length != 0 && this.closedTickets.length > 0){
					  const relevantTicket = _.filter(this.closedTickets, (res) => {      
						  return res["ticket_id"] == this.ticketId;
					  });  
					  if(relevantTicket.length > 0){
						  if(relevantTicket[0]["ticket_event_type_id"] == 4){
							  this.selectedTab = 2;
						  }else if(relevantTicket[0]["ticket_event_type_id"] == 3){
							  this.selectedTab = 1;
						  }else{
							  this.selectedTab = 0;
						  }
					  }	
					  this.dialog.closeAll();
					  if(relevantTicket.length > 0){
						if(relevantTicket[0]["ticket_event_type_id"] == 1){
							this.viewAction(relevantTicket[0],4,0);
						}else if(relevantTicket[0]["ticket_event_type_id"] == 3){
							this.viewAction(relevantTicket[0],4,1);
						}else if(relevantTicket[0]["ticket_event_type_id"] == 4){
							this.viewAction(relevantTicket[0],4,2);
						}
					  }
				  }
				  this.cd.detectChanges();
			  })
	  });  
	});
  }

  onResize() {
	this.responsiveService.getMobileStatus().subscribe(isMobile => {
		this.isMobile = isMobile;
	});
  }

  myTabSelectedIndexChange(event){
	this.selectedTab = event;
	if(this.isMobile){
		if(this.selectedTab == 0){
			this.tempCheckOrReplacementFilter = '';
			this.applyCheckOrReplacementFilter(this.tempCheckOrReplacementFilter);
		}else if(this.selectedTab == 1){
			this.tempInstallationFilter = '';
			this.applyInstallationFilter(this.tempInstallationFilter);
		}else if(this.selectedTab == 2){
			this.tempCancellationFilter = '';
			this.applyCancellationFilter(this.tempCancellationFilter);
		}
	}
  }

  handleExpandedElm(elm){
	if(this.expandedElementTable["ticket_id"] != elm["ticket_id"]){
		this.expandedElementTable = elm;
	}else{
		this.markerSelected = {};
		this.expandedElementTable = {};
	}
  }

  handleMarkerSelected(event){
	this.markerSelected = event;
	if(this.expandedElementTable["ticket_id"] != this.markerSelected["ticket_id"]){
		this.expandedElementTable = this.markerSelected;
	}
	const checkOrReplacementMarker = _.filter(this.checkOrReplacementTicketsArr, (res) => {      
        return res["ticket_id"] == this.markerSelected["ticket_id"];
    }); 
	const installationMarker = _.filter(this.installation, (res) => {      
        return res["ticket_id"] == this.markerSelected["ticket_id"];
    }); 
	const cancellationMarker = _.filter(this.cancellation, (res) => {      
        return res["ticket_id"] == this.markerSelected["ticket_id"];
    }); 
	if(checkOrReplacementMarker.length > 0){
		let index = this.filteredCheckOrReplacementData.findIndex(item => item["ticket_id"] === checkOrReplacementMarker[0]["ticket_id"]);
		let pageNumber = Math.floor(index / 10) + 1;
		let pageIndexes = {
			startIndex : pageNumber * 10 - 10,
			endIndex : pageNumber * 10 - 1
		}
		this.onCheckOrReplacementPageChange(pageIndexes);
	}else if(installationMarker.length > 0){
		let index = this.filteredInstallationData.findIndex(item => item["ticket_id"] === installationMarker[0]["ticket_id"]);
		let pageNumber = Math.floor(index / 10) + 1;
		let pageIndexes = {
			startIndex : pageNumber * 10 - 10,
			endIndex : pageNumber * 10 - 1
		}
		this.onInstallationPageChange(pageIndexes);
	}else if(cancellationMarker.length > 0){
		let index = this.filteredCancellationData.findIndex(item => item["ticket_id"] === cancellationMarker[0]["ticket_id"]);
		let pageNumber = Math.floor(index / 10) + 1;
		let pageIndexes = {
			startIndex : pageNumber * 10 - 10,
			endIndex : pageNumber * 10 - 1
		}
		this.onCancellationPageChange(pageIndexes);
	}
  }

  getRelevantBin(bins,ticket,status){
	if(_.groupBy(ticket["ticket_id_info"], "status_id")[status]){
		if(ticket["ticketData"].ticket_event_type_id == 3){
			if(_.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name != ''){
				const relevant_bin = bins.filter(bin => bin["site_id"] == ticket.site_id && bin["bin_name"] == _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name);
				if(relevant_bin.length > 0){
					ticket.bin_id = relevant_bin[0]["bin_id"];
				}else{
					ticket.bin_id = 0;
				}
			}else{
				ticket.bin_id = 0;
			}
		}else{
			const relevant_bin = bins.filter(bin => bin["site_id"] == ticket.site_id && bin["bin_name"] == _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].bin_name);
			ticket.bin_id = _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].bin_id;
			if(relevant_bin.length > 0 && ticket["ticketData"].ticket_event_type_id == 1 && ticket["ticketData"].ticket_event_cause_id == 3){	
				ticket.includedInWorkplan = relevant_bin[0]["bin_collection_mode"];
			}
		}
	}
  }

  deleteOwnEvent(event){
	if(event.user_id == this.user_id && this.user_role_id == 4){
		return true;
	}else{
		return false;
	}
  }

  onSelectDates = (selectDates: any) => {
	this.showDatePicker = false;
	this.selectedDates.from = selectDates.fromDate;
	this.selectedDates.to = selectDates.toDate;	
	if(moment(this.selectedDates.from, 'DD.MM.YYYY').isValid() && moment(this.selectedDates.to, 'DD.MM.YYYY').isValid()){
		this.minDate = moment(this.selectedDates.from, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:01');
		this.maxDate = moment(this.selectedDates.to, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59');	
	}else{
		this.minDate = '';
		this.maxDate = '';
	}

	sessionStorage.setItem("ticketManagementMinDate",this.minDate);
	sessionStorage.setItem("ticketManagementMaxDate",this.maxDate);
	sessionStorage.setItem("ticketManagementStartDate",this.minDate);
	sessionStorage.setItem("ticketManagementEndDate",this.maxDate);
	if(this.maxDate != this.lastMaxDate || this.minDate != this.lastMinDate){
		let ticketManagementRequest = {};   
		if(this.minDate == '' || this.maxDate == '' || this.minDate == null || this.maxDate == null){
			ticketManagementRequest = {
				status_id : -1
			}
		}else{
			ticketManagementRequest = {
				start_time : this.minDate,
				end_time : this.maxDate
			}
		}	
	  	
		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.lastMinDate = this.minDate;
		this.lastMaxDate = this.maxDate;
	}
  }

  getSavedStatusId(){
	if(this.minDate != '' && this.maxDate != ''){
		return 'display-dates';
	}else{
		return 'hide-label';
	}
  }

  getSavedStatusIdDefault(){
	if(this.minDate == '' && this.maxDate == ''){
		return 'display-dates';
	}else{
		return 'hide-label';
	}
  }

  showHideDatePicker = () => {
	this.showDatePicker = !this.showDatePicker;
  }

  openAddTicket(){
	let class_name = '';
	if(this.isMobile){
		class_name = 'popup-container-mobile';
	}else{
		class_name = 'bulkBins-container';
	}
	const dialogRef = this.dialog.open(AddTicketModalComponent, { panelClass: class_name , data: {selectedTab:this.selectedTab} });
	dialogRef.afterClosed().subscribe(result => {
		if(result.status == 1){
			this.eventAdded = true;
		}
	});
  }

  expandCheckOrReplacementMap(){
	this.mapCheckOrReplacementExpanded = !this.mapCheckOrReplacementExpanded;
  }

  expandInstallationMap(){
	this.mapInstallationExpanded = !this.mapInstallationExpanded;
  }

  expandCancellationMap(){
	this.mapCancellationExpanded = !this.mapCancellationExpanded;
  }

  ngOnDestroy() {
	sessionStorage.removeItem('ticketManagementMinDate');
	sessionStorage.removeItem('ticketManagementMaxDate');
	this.destroy$.next(true);
	this.destroy$.complete();
	if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
		this.cd.detectChanges();
	}
  }

  sortCheckOrReplacementTicketsData(sort: Sort) {
	const data = this.checkOrReplacementTicketsArr.slice();
	if (!sort.active || sort.direction === '') {
		this.checkOrReplacementTicketsArr = data;
		return;
	}
	this.checkOrReplacementTicketsArr = data.sort((a, b) => {
		const isAsc = sort.direction === 'asc';
		return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	});
  }

  sortInstallationTicketsData(sort: Sort) {
	const data = this.installation.slice();
	if (!sort.active || sort.direction === '') {
		this.installation = data;
		return;
	}
	this.installation = data.sort((a, b) => {
		const isAsc = sort.direction === 'asc';
		return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	});
  }

  sortCancellationTicketsData(sort: Sort) {
	const data = this.cancellation.slice();
	if (!sort.active || sort.direction === '') {
		this.cancellation = data;
		return;
	}
	this.cancellation = data.sort((a, b) => {
		const isAsc = sort.direction === 'asc';
		return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	});
  }

  compare(a, b, isAsc) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openColumnManaging(){
	this.dialog.open(GenericColumnsManagingModalComponent, { panelClass: 'popup-container-mobile' });
  }

  applyCheckOrReplacementFilter = (filterValue: string) => {
	filterValue = filterValue.trim();
	filterValue = filterValue.toLowerCase();
	this.tempCheckOrReplacementFilter = filterValue;
	this.checkOrReplacementDataSource.filter = filterValue;
  }

  applyInstallationFilter = (filterValue: string) => {
	filterValue = filterValue.trim();
	filterValue = filterValue.toLowerCase();
	this.tempInstallationFilter = filterValue;
	this.installationDataSource.filter = filterValue;
  }

  applyCancellationFilter = (filterValue: string) => {
	filterValue = filterValue.trim();
	filterValue = filterValue.toLowerCase();
	this.tempCancellationFilter = filterValue;
	this.cancellationDataSource.filter = filterValue;
  }

  getCheckOrReplacementData = () => {
	const tableData = [];
	const tempSearch = new MatTableDataSource<any>(this.checkOrReplacementTicketsArr);
	tempSearch.filter = this.tempCheckOrReplacementFilter;
	this.filteredCheckOrReplacementData = tempSearch.filteredData;
	for (let index = this.startIndexTableDataCheckOrReplacement; index <= this.endIndexTableDataCheckOrReplacement; index++) {
		if (tempSearch.filteredData[index]) {
			tableData.push(tempSearch.filteredData[index]);
		}
	}
	this.checkOrReplacementDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
	return this.checkOrReplacementDataSource;
  }

  getInstallationData = () => {
	const tableData = [];
	const tempSearch = new MatTableDataSource<any>(this.installation);
	tempSearch.filter = this.tempInstallationFilter;
	this.filteredInstallationData = tempSearch.filteredData;
	for (let index = this.startIndexTableDataInstallation; index <= this.endIndexTableDataInstallation; index++) {
		if (tempSearch.filteredData[index]) {
			tableData.push(tempSearch.filteredData[index]);
		}
	}
	this.installationDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
	return this.installationDataSource;
  }

  getCancellationData = () => {
	const tableData = [];
	const tempSearch = new MatTableDataSource<any>(this.cancellation);
	tempSearch.filter = this.tempCancellationFilter;
	this.filteredCancellationData = tempSearch.filteredData;
	for (let index = this.startIndexTableDataCancellation; index <= this.endIndexTableDataCancellation; index++) {
		if (tempSearch.filteredData[index]) {
			tableData.push(tempSearch.filteredData[index]);
		}
	}
	this.cancellationDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
	return this.cancellationDataSource;
  }

  onCheckOrReplacementPageChange = (dataTableIndexes: any) => {
	this.startIndexTableDataCheckOrReplacement = dataTableIndexes.startIndex;
	this.endIndexTableDataCheckOrReplacement = dataTableIndexes.endIndex;
	this.getCheckOrReplacementData();
  }

  onInstallationPageChange = (dataTableIndexes: any) => {
	this.startIndexTableDataInstallation = dataTableIndexes.startIndex;
	this.endIndexTableDataInstallation = dataTableIndexes.endIndex;
	this.getInstallationData();
  }

  onCancellationPageChange = (dataTableIndexes: any) => {
	this.startIndexTableDataCancellation = dataTableIndexes.startIndex;
	this.endIndexTableDataCancellation = dataTableIndexes.endIndex;
	this.getCancellationData();
  }

  viewAction(item,status,tab){
	this.dialog.open(ViewTicketModalComponent, { panelClass: 'bulkBins-container' , data: {chosenTicket : item,status: status,selectedTab:tab} });
  }

  deleteAction(item,status){	
	const request = {   
		"event_cause_id": item.ticket_event_cause_id,
		"event_type_id":item.ticket_event_type_id,
		"priority_id":item.priority_id,
		"ticket_id":item.ticket_id,
		"comment":_.groupBy(item["ticketInfo"], "status_id")[status] ? _.groupBy(item["ticketInfo"], "status_id")[status][0]["comment"] : '',
		"status_id":5,
		"site_id":item.site_id,
		"expected_arrival_date":item.expected_arrival_date
	  }
	  
	  if(item.ticket_event_type_id == "1"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["analysis_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].analysis_id;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
		request["is_replaced"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_replaced;
	  }else if(item.ticket_event_type_id == "3"){
		request["is_installed"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_installed;
		request["bin_install_address"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_address;
		request["lat"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lat;
		request["lon"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lon;
		request["installation_type_id"] = 0;
		request["installed_bin_neighborhood"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_neighborhood;
	  }else if(item.ticket_event_type_id == "4"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["is_removed_from_bin"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_bin;
		request["is_removed_from_site"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_site;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
	  }
	  
	  this.apiService.insertNewTicket(request).subscribe((v:any) => {     				
		let ticketManagementRequest = {};   
		if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
		sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
			ticketManagementRequest = {
				status_id : -1
			  }
		}else{
			ticketManagementRequest = {
				start_time : sessionStorage.getItem("ticketManagementMinDate"),
				end_time : sessionStorage.getItem("ticketManagementMaxDate")
			  }
		}				

		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allTicketsList: [] });
		this.apiStore.update({ ticketsList: [] });
		this.apiService.getTicketsList(JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allFilteredBins: [] });                    
		this.apiStore.update({ filteredBins: [] });
		this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allFilteredBasicBins: [] });                    
		this.apiStore.update({ filteredBasicBins: [] }); 
		this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
	  });
  }

  resolveAction(item,status,tab){	
	let class_name = '';
	if(this.isMobile){
		class_name = 'popup-container-mobile';
	}else{
		class_name = 'bulkBins-container';
	}
	const dialogRef = this.dialog.open(ResolveTicketModalComponent, { panelClass: class_name , data: {chosenTicket : item,status: status,selectedTab:tab} });
	dialogRef.afterClosed().subscribe(result => {
		this.eventAdded = false;
	});
  }

  getDirection(){    
    if(this.currentLang != 'iw'){
      return 'ltr';
    }
    else{
        return 'rtl';
      }
  }

  exportToCsv(status){
	let dataToExport = null;
	if(status == 0){
		dataToExport = _.cloneDeep(this.checkOrReplacementTicketsArr);
	}else if(status == 1){
		dataToExport = _.cloneDeep(this.installation);
	}else if(status == 2){
		dataToExport = _.cloneDeep(this.cancellation);
	}
	_.each(dataToExport, (item) => {
		if(this.currentLang == 'iw'){
			item.eventCauseEn = item.eventCauseHe;
			item.priorityEn = item.priorityHe;
			item.created = item.created_he;
			item.expected_arrival_date = item.expected_arrival_date_he;
			item.closed = item.closed_he;
		}
	});

	let header = {};
	if(status == 0){
		header = {
			'0' : this.translationsObj.TICKET_MANAGEMENT.EVENT_ID,
			'1' : this.translationsObj.TICKET_MANAGEMENT.STATUS,
			'2' : this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE, 
			'3' : this.translationsObj.TICKET_MANAGEMENT.PRIORITY,
			'4' : this.translationsObj.TICKET_MANAGEMENT.SITE,
			'5' : this.translationsObj.TICKET_MANAGEMENT.BIN_NAME, 
			'6' : this.translationsObj.TICKET_MANAGEMENT.BIN_ADDRESS, 
			'7' : this.translationsObj.TICKET_MANAGEMENT.EVENT_DESCRIPTION,
			'8' : this.translationsObj.TICKET_MANAGEMENT.REPORTER,
			'9' : this.translationsObj.TICKET_MANAGEMENT.CREATED,
			'10' : this.translationsObj.TICKET_MANAGEMENT.ARRIVAL_DATE,
			'11' : this.translationsObj.TICKET_MANAGEMENT.CLOSED,
			'12' : this.translationsObj.TICKET_MANAGEMENT.INSTALLER_DESC
		}
	}else if(status == 1){
		header = {
			'0' : this.translationsObj.TICKET_MANAGEMENT.EVENT_ID,
			'1' : this.translationsObj.TICKET_MANAGEMENT.STATUS,
			'2' : this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE, 
			'3' : this.translationsObj.TICKET_MANAGEMENT.PRIORITY,
			'4' : this.translationsObj.TICKET_MANAGEMENT.SITE,
			'5' : this.translationsObj.TICKET_MANAGEMENT.BIN_NAME, 
			'6' : this.translationsObj.TICKET_MANAGEMENT.BIN_ADDRESS, 
			'7' : this.translationsObj.TICKET_MANAGEMENT.EVENT_DESCRIPTION,
			'8' : this.translationsObj.TICKET_MANAGEMENT.REPORTER,
			'9' : this.translationsObj.TICKET_MANAGEMENT.CREATED,
			'10' : this.translationsObj.TICKET_MANAGEMENT.ARRIVAL_DATE,
			'11' : this.translationsObj.TICKET_MANAGEMENT.CLOSED,
			'12' : this.translationsObj.TICKET_MANAGEMENT.INSTALLER_DESC
		}
	}else if(status == 2){
		header = {
			'0' : this.translationsObj.TICKET_MANAGEMENT.EVENT_ID,
			'1' : this.translationsObj.TICKET_MANAGEMENT.STATUS,
			'2' : this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE, 
			'3' : this.translationsObj.TICKET_MANAGEMENT.PRIORITY,
			'4' : this.translationsObj.TICKET_MANAGEMENT.SITE,
			'5' : this.translationsObj.TICKET_MANAGEMENT.BIN_NAME, 
			'6' : this.translationsObj.TICKET_MANAGEMENT.BIN_ADDRESS, 
			'7' : this.translationsObj.TICKET_MANAGEMENT.EVENT_DESCRIPTION,
			'8' : this.translationsObj.TICKET_MANAGEMENT.REPORTER,
			'9' : this.translationsObj.TICKET_MANAGEMENT.CREATED,
			'10' : this.translationsObj.TICKET_MANAGEMENT.ARRIVAL_DATE,
			'11' : this.translationsObj.TICKET_MANAGEMENT.CLOSED,
			'12' : this.translationsObj.TICKET_MANAGEMENT.INSTALLER_DESC
		}
	}

	const flatData = dataToExport.reduce((b, a) => {	
		let res = _.slice(_.values(a), 0, Object.keys(header).length)									
		b.push(res);
		return b;
	}, [])
	
	let source = {
		flatData,
		header
	};	

	let result = source.flatData.map((details) => 
	Object.keys(details).reduce((a, b) => 
	(a[source.header[b]] = details[b], a), {}));			
	if(status == 0){
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "check or replacement");
		}else{
			TableUtil.exportArrayToExcel(result, "בדיקה או החלפה");
		}
	}else if(status == 1){
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "installation");
		}else{
			TableUtil.exportArrayToExcel(result, "התקנה");
		}
	}else if(status == 2){
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "cancellation");
		}else{
			TableUtil.exportArrayToExcel(result, "הסרה");
		}
	}
  }

  closeAction(item,status){	
	const request = {   
		"event_cause_id": item.ticket_event_cause_id,
		"event_type_id":item.ticket_event_type_id,
		"priority_id":item.priority_id,
		"ticket_id":item.ticket_id,
		"comment":_.groupBy(item["ticketInfo"], "status_id")[status] ? _.groupBy(item["ticketInfo"], "status_id")[status][0]["comment"] : '',
		"status_id":4,
		"site_id":item.site_id,
		"expected_arrival_date":item.expected_arrival_date
	  }
	  
	  if(item.ticket_event_type_id == "1"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["analysis_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].analysis_id;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
		request["is_replaced"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_replaced;
	  }else if(item.ticket_event_type_id == "3"){
		request["is_installed"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_installed;
		request["bin_install_address"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_address;
		request["lat"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lat;
		request["lon"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_lon;
		request["installation_type_id"] = 0;
		request["installed_bin_neighborhood"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].installed_bin_neighborhood;
	  }else if(item.ticket_event_type_id == "4"){
		request["bin_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].bin_id;
		request["is_removed_from_bin"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_bin;
		request["is_removed_from_site"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].is_removed_from_site;
		request["ticket_remove_replace_cause_id"] = _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id ? _.groupBy(item["ticketInfo"], "status_id")[status][0].replace_cause_id : 0;
	  }
	  this.apiService.insertNewTicket(request).subscribe((v:any) => { 								
		let ticketManagementRequest = {};   
		if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
		sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
			ticketManagementRequest = {
				status_id : -1
			  }
		}else{
			ticketManagementRequest = {
				start_time : sessionStorage.getItem("ticketManagementMinDate"),
				end_time : sessionStorage.getItem("ticketManagementMaxDate")
			  }
		}				

		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
	  });
  }

  editAction(item,status,tab){
	let class_name = '';
	if(this.isMobile){
		class_name = 'popup-container-mobile';
	}else{
		class_name = 'bulkBins-container';
	}
	const dialogRef = this.dialog.open(EditTicketModalComponent, { panelClass: class_name , data: {chosenTicket : item,status: status,selectedTab:tab} });
	dialogRef.afterClosed().subscribe(result => {
		if(result.status == 1){
			this.eventAdded = true;
		}
	});
  }
}
