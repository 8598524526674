@if (!isMobile) {
  <div>
    <div class="top-container">
      @if (siteObject) {
        <div class="site-config-header">{{ siteObject['site_name'] }}</div>
      }
      <mat-divider class="top-divider"></mat-divider>
    </div>
    <div class="padded site-config-page">
      <mat-grid-list cols="2" rowHeight="2:1">
        <mat-grid-tile class="mat-grid-tile">
          <ul><h3>{{ 'SITE_MANAGMENT.BINS' | translate }}</h3>
          <li><a routerLink="/binsmonitoring/{{ siteId }}">{{ 'WIDGETS.MANAGE_BINS' | translate }}</a></li>
          <li><a routerLink="/swapUnit/{{ siteId }}">{{ 'SITE_MANAGMENT.SWAP_UNIT' | translate }}</a></li>
        </ul>
      </mat-grid-tile>
      <mat-grid-tile class="mat-grid-tile">
        <ul><h3>{{ 'SITE_MANAGMENT.PRE_INS' | translate }}</h3>
        <li><a routerLink="/preMapping/{{ siteId }}">{{ 'SITE_MANAGMENT.PRE_MAP_WIZARED' | translate }}</a></li>
        <li><a routerLink="/preInstallationMapping/{{ siteId }}">{{ 'SITE_MANAGMENT.MAP_WIZ' | translate }}</a></li>
      </ul>
    </mat-grid-tile>
  </mat-grid-list>
</div>
</div>
}

@if (isMobile) {
  <div>
    <div class="mobile-top-container">
      @if (siteObject) {
        <div class="mobile-management-header">
          <span class="mobile-left-title">{{ siteObject['site_name'] }}</span>
        </div>
      }
      <div class="mobile-separate">
        <mat-divider class="mobile-line"></mat-divider>
      </div>
    </div>
    <div class="accordion md-accordion" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="card-header mobile-header">
          <span class="mobile-title"> {{ 'SITE_MANAGMENT.BINS' | translate }} </span>
        </div>
        <div class="collapse show" role="tabpanel">
          <div class="card-body">
            <div class="mobile-title-sub"><a routerLink="/binsmonitoring/{{ siteId }}">{{ 'WIDGETS.MANAGE_BINS' | translate }}</a></div>
            <div class="mobile-title-sub"><a routerLink="/swapUnit/{{ siteId }}">{{ 'SITE_MANAGMENT.SWAP_UNIT' | translate }}</a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion md-accordion" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card last-card">
        <div class="card-header mobile-header">
          <span class="mobile-title"> {{ 'SITE_MANAGMENT.PRE_INS' | translate }} </span>
        </div>
        <div class="collapse show" role="tabpanel">
          <div class="card-body">
            <div class="mobile-title-sub"><a routerLink="/preMapping/{{ siteId }}">{{ 'SITE_MANAGMENT.PRE_MAP_WIZARED' | translate }}</a></div>
            <div class="mobile-title-sub"><a routerLink="/preInstallationMapping/{{ siteId }}">{{ 'SITE_MANAGMENT.MAP_WIZ' | translate }}</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

