@if (!isMobile) {
  <div class="bin-list-view">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">{{ 'TITLES.LIST_VIEW' | translate }}</mat-card-title>
        <div class="close-btn" rtlDiv (click)="closeCMP()">
          <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
        </div>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div class='search-input-container'>
          <div class="search-frame">
            <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
            <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}" name="" rtlDiv>
          </div>
            <div class='table-export-container' rtlDiv>
              <div class='table-export-btns-wrapper' (click)="exportToCsv()">
                <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
              </div>
              <div class="manage-bins-btn-container" [hidden]="spinnerActive" rtlDiv>
                <a routerLink="/inventory" class="manage-bins-btn" rtlDiv>
                  @if (usageType == 1) {
                    <span>{{ 'WIDGETS.MANAGE_BINS' | translate }}</span>
                  }
                  @if (usageType != 1) {
                    <span>{{ 'WIDGETS.MANAGE_TANKS' | translate }}</span>
                  }
                  <span>
                    <img class="arow_manage" src="assets/images/dashboard/Arrow_right.svg" rtlDiv/>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_name"
            matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th [hidden]="!binNameCol && usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="list-title th-title">{{ 'LIST_COLUMNS.NAME' | translate }} </th>
              <td [hidden]="!binNameCol && usageType == 2" mat-cell *matCellDef="let element">
                <a class="binNameHref" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'dashboard',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" [ngClass]="{'cut-txt' : element && element.bin_name.length > 11}"> {{element ? element.bin_name : ''}}</a>
              </td>
            </ng-container>
            <!-- id Column -->
            <ng-container matColumnDef="id">
              <th [hidden]="!binIdCol" mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="list-title space th-title">{{ 'LIST_COLUMNS.ID' | translate }}</th>
              <td [hidden]="!binIdCol" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.bin_id: ''}} </td>
            </ng-container>
            <!-- device id Column -->
            <ng-container matColumnDef="device_id">
              <th [hidden]="!binDeviceIdCol" mat-header-cell *matHeaderCellDef mat-sort-header="device_id" class="list-title space th-title">{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}</th>
              <td [hidden]="!binDeviceIdCol" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.device_id: ''}} </td>
            </ng-container>
            <!-- type Column -->
            <ng-container matColumnDef="type">
              <th [hidden]="!binTypeCol" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type" class="list-title space th-title">{{ usageType == 1 ? (translationsObj ? translationsObj.LIST_COLUMNS.BIN_TYPE : '') : (translationsObj ? translationsObj.LIST_COLUMNS.TANK_TYPE : '')}}</th>
              <td [hidden]="!binTypeCol" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.bin_type: ''}} </td>
            </ng-container>
            <!-- Address Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="address" class="list-title space th-title">{{ 'LIST_COLUMNS.ADDRESS'
              | translate }}</th>
              <td mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.address: ''}} </td>
            </ng-container>
            <!-- neighborhood Column -->
            <ng-container matColumnDef="neighborhood">
              <th [hidden]="!neighborhood" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood" class="list-title space th-title">{{
                'LIST_COLUMNS.NEIGHBORHOOD' | translate
              }}</th>
              <td [hidden]="!neighborhood" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.neighborhood: ''}} </td>
            </ng-container>
            <!-- description Column -->
            <ng-container matColumnDef="cluster_id">
              <th [hidden]="usageType == 1" mat-header-cell *matHeaderCellDef mat-sort-header="cluster_id" class="list-title space th-title"> {{
                'LIST_COLUMNS.DESCRIPTION' | translate
              }} </th>
              <td [hidden]="usageType == 1" mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.description: ''}} </td>
            </ng-container>
            <!-- description Column -->
            <ng-container matColumnDef="description">
              <th [hidden]="usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="description" class="list-title space th-title"> {{
                'LIST_COLUMNS.DESCRIPTION' | translate
              }} </th>
              <td [hidden]="usageType == 2" mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.description: ''}} </td>
            </ng-container>
            <!-- last_collection Column -->
            <ng-container matColumnDef="last_refill">
              <th [hidden]="usageType == 1" mat-header-cell *matHeaderCellDef mat-sort-header="last_refill" class="list-title space th-title">{{
                'LIST_COLUMNS.LAST_REFILL' |
              translate }}</th>
              <td [hidden]="usageType == 1" mat-cell *matCellDef="let element" class="td-value space"> {{element.calcDiff_lastRefill}} </td>
            </ng-container>
            <!-- last_collection Column -->
            <ng-container matColumnDef="last_collection">
              <th [hidden]="usageType != 1" mat-header-cell *matHeaderCellDef mat-sort-header="last_collection" class="list-title space th-title">{{
                'LIST_COLUMNS.LAST_COLLECTION' |
              translate }}</th>
              <td [hidden]="usageType != 1" mat-cell *matCellDef="let element" class="td-value space"> {{element.calcDiff_lastCollection}} </td>
            </ng-container>
            <!-- status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="list-title th-title">
                {{ 'LIST_COLUMNS.STATUS' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapper(element)">
                  <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                </div>
                <div class='table-status-progress-bar-title' rtlDiv>{{element ? element.bin_capacity_to_display: ''}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="plus">
              <th mat-header-cell *matHeaderCellDef class="list-title th-title">
                <img rtlDiv class="dropdown-toggle addBtn" data-bs-toggle="dropdown" src="assets/images/dashboard/addCol.svg" alt=""/>
                <div class="dropdown-menu list-items" rtlDiv>
                  <label class="container-extra" rtlDiv [hidden]="usageType == 2">
                    {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}
                    <input type="checkbox" [(ngModel)]="isNeighborhoodChecked" [checked]="neighborhood" (change)="toggleEditable(isNeighborhoodChecked)" rtlDiv>
                    <span class="checkmark" rtlDiv></span>
                  </label>
                  <label class="container-extra" rtlDiv [hidden]="usageType == 1">
                    {{ 'LIST_COLUMNS.NAME' | translate }}
                    <input type="checkbox" [(ngModel)]="isBinNameColChecked" [checked]="binNameCol" (change)="toggleEditableName(isBinNameColChecked)" rtlDiv>
                    <span class="checkmark" rtlDiv></span>
                  </label>
                  <label class="container-extra" rtlDiv>
                    {{ 'LIST_COLUMNS.ID' | translate }}
                    <input type="checkbox" [(ngModel)]="isBinIdColChecked" [checked]="binIdCol" (change)="toggleEditableId(isBinIdColChecked)" rtlDiv>
                    <span class="checkmark" rtlDiv></span>
                  </label>
                  <label class="container-extra" rtlDiv>
                    @if (usageType == 1) {
                      <span>{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</span>
                    }
                    @if (usageType != 1) {
                      <span>{{ 'LIST_COLUMNS.TANK_TYPE' | translate }}</span>
                    }
                    <input type="checkbox" [(ngModel)]="isBinTypeColChecked" [checked]="binTypeCol" (change)="toggleEditableType(isBinTypeColChecked)" rtlDiv>
                    <span class="checkmark" rtlDiv></span>
                  </label>
                  <label class="container-extra" rtlDiv>
                    {{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}
                    <input type="checkbox" [(ngModel)]="isBinDeviceIdColChecked" [checked]="binDeviceIdCol" (change)="toggleEditableDeviceId(isBinDeviceIdColChecked)" rtlDiv>
                    <span class="checkmark" rtlDiv></span>
                  </label>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">            
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <!-- <mat-paginator #paginator [hidePageSize]="true" [pageSize]="6" [showFirstLastButtons]="false">
        </mat-paginator> -->
      </mat-card-content>
      <div [hidden]="filteredData.length == 0">
        <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="6"></app-pagination>
      </div>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="card-header mobile-header">
          <span class="mobile-title"> {{ 'TITLES.LIST_VIEW' | translate }}</span>
          <div class="mobile-close-btn" rtlDiv (click)="closeCMP()">
            <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
          </div>
        </div>
        <div class="collapse show" role="tabpanel">
          <div class="card-body">
            <div>
              <div class='mobile-search-container'>
                <input class='mobile-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                  id="mobile-bin-list-view" rtlDiv>
                  <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                </div>
                <div [ngClass]="{'hide':spinnerExist,'show':!spinnerExist}" class="spinner-border mobile-spinner" role="status">
                  <span class="sr-only"></span>
                </div>
                <table class="mobile-table" #table mat-table [dataSource]="getTableData()" multiTemplateDataRows
                  [ngClass]="{'showTable':filteredExist,'hide':!filteredExist}"
                  matSort matSortStart="asc" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc"
                  matSortActive="bin_name" rtlDiv>
                  <ng-container matColumnDef="name">
                    <th [hidden]="usageType == 2" class="mobile-th" style="height: 52px;" mat-sort-header="bin_name" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' |
                    translate }} </th>
                    <td [hidden]="usageType == 2" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)">
                      <span title="{{element ? element.bin_name : ''}}" class="cut-txt"> {{element ? element.bin_name : ''}}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="cluster_id">
                      <th [hidden]="usageType == 1" class="mobile-th" style="height: 52px;" mat-sort-header="cluster_id" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.DESCRIPTION' |
                      translate }} </th>
                      <td [hidden]="usageType == 1" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)">
                        <span title="{{element ? element.description : ''}}" class="cut-txt"> {{element ? element.description : ''}}</span> </td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-sort-header="status" class="mobile-th mobile-status-pos" style="height: 52px;"  mat-header-cell *matHeaderCellDef>
                        {{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                        <td class="mobile-status-td" mat-cell *matCellDef="let element">
                          <div rtlDiv class="mobile-status-value" (click)="openDetails()"
                            [ngStyle]="getProgressBarStyleInternal(element)">
                            <span class="spanSize">{{element ? element.bin_capacity_to_display: ''}}</span>
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="arrowDown">
                        <th class="mobile-th" style="height: 52px;" mat-sort-header="arrowDown" mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                          <div rtlDiv class="mobile-status-value">
                            @if (expandedElement !== element) {
                              <img class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                            }
                            @if (expandedElement === element) {
                              <img class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                            }
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="expandedDetail">
                        <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                          <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="mobile-element-diagram" rtlDiv>
                              <div class="dFlex">
                                <div class="mobile-id-details-title">{{ 'LIST_COLUMNS.ID' | translate }}</div>
                                <div class="mobile-id-details-value"> {{element.bin_id}} </div>
                              </div>
                              <div class="dFlex">
                                <div class="mobile-address-details-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</div>
                                <div class="mobile-address-details-value"> {{element.address}} </div>
                              </div>
                              <div class="dFlex">
                                <div class="mobile-description-details-title">{{'LIST_COLUMNS.DESCRIPTION' | translate}}</div>
                                <div class="mobile-description-details-value"> {{element.description}} </div>
                              </div>
                              <div class="dFlex" [hidden]="usageType == 1">
                                <div class="mobile-collection-details-title">{{'LIST_COLUMNS.LAST_REFILL' | translate }}</div>
                                <div class="mobile-collection-details-value"> {{parseDateFormat(element.last_refill)}} </div>
                              </div>
                              <div class="dFlex" [hidden]="usageType != 1">
                                <div class="mobile-collection-details-title">{{'LIST_COLUMNS.LAST_COLLECTION' | translate }}</div>
                                <div class="mobile-collection-details-value"> {{parseDateFormat(element.last_collection)}} </div>
                              </div>
                              <div class="dFlex">
                                <div class="mobile-description-details-title">{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}</div>
                                <div class="mobile-description-details-value"> {{element.device_id}} </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr style="display: flex;width: 100%;" mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                    </table>
                    <div [hidden]="filteredData.length == 0">
                      <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="6"></app-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }