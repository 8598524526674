import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

@Component({
  selector: 'app-manage-user-modal',
  standalone: true,
  imports: [TranslateModule,MatDividerModule,CommonModule,RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,
    MatSelectModule,MatNativeDateModule,MatInputModule],
  templateUrl: './manage-user-modal.component.html',
  styleUrl: './manage-user-modal.component.scss'
})
export class ManageUserModalComponent {
  ElForm: FormGroup;
	currentLang;
	translationsObj;
  isMobile: Boolean;
  userRoles:Array<object> = [];
  sitesList:any[] = [];
  usageTypes:any[] = [];
  capacityDisplayTypes:any[] = [];
	userAccounts:Array<object> = [];
  options: FormGroup;

  constructor(private dialogRef: MatDialogRef<ManageUserModalComponent>,private translator: TranslatorService,private fb: FormBuilder,
    private responsiveService: ResponsiveService,@Inject(MAT_DIALOG_DATA) public data: any,private apiQuery:ApiQuery,
    private apiService:ApiService,private apiStore:ApiStore) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})

    this.options = fb.group({
      floatLabel: 'never'
    });

    this.ElForm = this.fb.group({
      userName:new FormControl('', Validators.required),
      email:new FormControl('', Validators.required),
      firstName:new FormControl(''),
      lastName:new FormControl(''),
      phoneNumber:new FormControl(''),
      company:new FormControl(''),
      permissions:new FormControl('', Validators.required),
      sitesList:new FormControl('', Validators.required),
      usageType:new FormControl('', Validators.required),
      capacityDisplayType:new FormControl('', Validators.required),
      userAccount:new FormControl('', Validators.required)
    });
   }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.apiQuery.userRoles$.subscribe((v:any) => {	       
      if(v.length == 0) {return;}    
      this.userRoles = v;  
      this.ElForm.controls['permissions'].setValue(Number(this.data.chosenUser.user_role_id));
    });

    this.apiQuery.sitesListData$.subscribe((sitesList) => {
			if(sitesList.length == 0) return;
      let sitesToSelect = [];
			this.sitesList = sitesList;
      _.each(this.sitesList, (site:any) => {
        if(this.data.chosenUser.user_sites_Info.some(siteInfo => siteInfo.site_id == site.site_id)){
          sitesToSelect.push(site.site_id);
        }
      }); 
      this.ElForm.controls['sitesList'].setValue(sitesToSelect);
		});	

    this.apiQuery.usageTypes$.subscribe((type) => {
      if(type.length == 0){return;}    
      this.usageTypes = type;      
      this.ElForm.controls['usageType'].setValue(Number(this.data.chosenUser.usage_type_id));                                                                                              
    });

    this.apiQuery.capacityDisplayTypes$.subscribe((type) => {
      if(type.length == 0){return;}  
      this.capacityDisplayTypes = type;  
      this.ElForm.controls['capacityDisplayType'].setValue(Number(this.data.chosenUser.capacity_display_type_id));                                                                                                                                                 
    });

    this.apiQuery.userAccounts$.subscribe((accounts) => {
      if(accounts.length == 0){return;}  
      this.userAccounts = accounts;  
      this.ElForm.controls['userAccount'].setValue(Number(this.data.chosenUser.account_id));                                                                                                                                                 
    });

    this.ElForm.controls['userName'].setValue(this.data.chosenUser.user_name);
    this.ElForm.controls['email'].setValue(this.data.chosenUser.user_email_address);         
    this.ElForm.controls['firstName'].setValue(this.data.chosenUser.first_name);         
    this.ElForm.controls['lastName'].setValue(this.data.chosenUser.last_name);         
    this.ElForm.controls['phoneNumber'].setValue(this.data.chosenUser.user_phone_number);    
    this.ElForm.controls['company'].setValue(this.data.chosenUser.user_company);
  }

  
  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  applyChanges(){
		if(!this.ElForm.valid){
			return;
		}
    let sitesToSend = [];
    let request = {};
    request["email_address"] = this.ElForm.getRawValue()["email"]; 
    request["user_password"] = '';     
    request["user_first_name"] = this.ElForm.getRawValue()["firstName"]; 
    request["user_last_name"] = this.ElForm.getRawValue()["lastName"]; 
    request["user_name"] = this.ElForm.getRawValue()["userName"]; 
    request["account_id"] = this.ElForm.getRawValue()["userAccount"]; 
    request["user_phone_number"] = this.ElForm.getRawValue()["phoneNumber"];  
    request["user_role_id"] = this.ElForm.getRawValue()["permissions"]; 
    request["user_time_zone"] = ''; 
    request["notification_id_list"] = ''; 
    request["is_web_notification"] = 0; 
    request["is_email"] = 0; 
    request["is_sms"] = 0;
    request["usage_type_id"] = this.ElForm.getRawValue()["usageType"];
    request["capacity_display_type_id"] = this.ElForm.getRawValue()["capacityDisplayType"];
    request["default_lang_id"] = 2;
    request["user_company"] = this.ElForm.getRawValue()["company"];
    request["user_id"] = this.data.chosenUser.user_id; 
    _.each(this.ElForm.getRawValue()["sitesList"], (siteId) => {
      sitesToSend.push([siteId,0]);
    });
    _.each(this.data.chosenUser.user_sites_Info, (siteInfo) => {
      if(!this.ElForm.getRawValue()["sitesList"].includes(siteInfo.site_id) && this.sitesList.some(site => site["site_id"] == siteInfo.site_id)){
        sitesToSend.push([siteInfo.site_id,1]);
      }
    });
    request["sites_id_list"] = sitesToSend; 
    this.apiService.updateUserParams(request).subscribe((v:any) => {
      this.apiStore.update({ filteredUsers: [] });	
      this.apiService.getfilteredUsersInfo(JSON.parse(sessionStorage.getItem("chosenSites")));         
      this.closeModal();  
    });
	}

  closeModal(){
		this.dialogRef.close();
	}
}
