import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { ApiQuery } from '../../web-services/api/api.query';

@Component({
  selector: 'app-generic-columns-managing-modal',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule],
  templateUrl: './generic-columns-managing-modal.component.html',
  styleUrl: './generic-columns-managing-modal.component.scss'
})
export class GenericColumnsManagingModalComponent {
  isMobile: boolean;
	translationsObj;
	currentLang;
  InventoryForm : FormGroup;
  CollectionForm : FormGroup;
  TicketManagementForm : FormGroup;
	usageType = 1;
	user_role_id = 0;

  constructor(private responsiveService: ResponsiveService,private dialogRef: MatDialogRef<GenericColumnsManagingModalComponent>,
    private translator: TranslatorService,private fb: FormBuilder,private apiQuery:ApiQuery) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;	
    }); 
    this.InventoryForm = this.fb.group({
      isBinNameChecked : new FormControl(false),
      isAddressChecked : new FormControl(false),
      isBinDescChecked : new FormControl(false),
      isLastCollectionChecked : new FormControl(false),
      isLastRefillChecked : new FormControl(false),
      isLastUpdateChecked : new FormControl(false),
      isStatusChecked : new FormControl(false),
			isNeighborhoodChecked : new FormControl(false),
      isBinNameColChecked : new FormControl(false),
      isBinSiteColChecked : new FormControl(false),
      isBinIdColChecked : new FormControl(false),
      isBinTypeColChecked : new FormControl(false),
      isBinInstallDateColChecked : new FormControl(false),
      isBinAvgRateColChecked : new FormControl(false),
      isBinAvgUsageRateChecked : new FormControl(false),
      isBinCollectionFrequencyChecked : new FormControl(false),
      isAvgDailyRateDunamColChecked : new FormControl(false),
      isBinCollectionModeChecked : new FormControl(false),
      isBinDeviceIdColChecked : new FormControl(false)
		});
    this.CollectionForm = this.fb.group({
      isBinNameChecked : new FormControl(false),
      isAddressChecked : new FormControl(false),
      isTotalCollectionsChecked : new FormControl(false),
      isAvgCapAtPickUpChecked : new FormControl(false),
      isSiteNameChecked : new FormControl(false),
      isNeighborhoodChecked : new FormControl(false),
      isBinTypeColChecked : new FormControl(false),
      isBinNameColChecked : new FormControl(false)
    });
    this.TicketManagementForm = this.fb.group({
      isTicketIdChecked : new FormControl(false),
      isTicketStatusChecked : new FormControl(false),
      isTicketCauseChecked : new FormControl(false),
      isTicketPriorityChecked : new FormControl(false),
      isTicketSiteChecked : new FormControl(false),
      isBinNameChecked : new FormControl(false),
      isTicketAddressChecked : new FormControl(false),
      isDescChecked : new FormControl(false),
      isReporterChecked : new FormControl(false),
      isTicketCreatedChecked : new FormControl(false),
      isArrivalDateChecked : new FormControl(false),
      isTicketClosedChecked : new FormControl(false)
    });
   }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();

    this.apiQuery.user$.subscribe((user) => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
      this.user_role_id = user["user_role_id"];
    });
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  closeModal(){
		this.dialogRef.close();
	}

  applyChanges(){    
  }
}
