@if (!isMobile) {
  <div>
    <div class="inventory-view">
      <mat-card>
        <mat-card-header>
          <mat-card-title class="title">{{ 'TITLES.ALL_COLLECTIONS' | translate }} {{datesDiff}}</mat-card-title>
        </mat-card-header>
        <mat-divider class="top-divider"></mat-divider>
        <div class='spinner-container' [hidden]="!spinnerActive">
          <div class="spinner"></div>
        </div>
        <mat-card-content [hidden]="spinnerActive">
          <div class="flexFilter">
            <div class='search-input-container' rtlDiv>
              <div class="search-frame">
                <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                <input class='search-input' type="text" (input)="applyFilter($event.target)" placeholder="{{
                  'OTHER.SEARCH' | translate }}" name="">
              </div>
            </div>
              @if (filterTableAccordigToStatus.text != '') {
                <div class="filteredValue">
                  <svg height="8" width="8">
                    <ellipse cx="4" cy="4" rx="4" ry="4" [ngStyle]="{fill: filterTableAccordigToStatus.value}"/>
                  </svg>
                  <span>{{filterTableAccordigToStatus.text}}</span>
                  <img (click)="removeFilter()" class="removeFilter" src="assets/images/reports/removeFilter.svg" alt=""/>
                </div>
              }
            </div>
            <mat-divider class="top-divider"></mat-divider>
            <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
              matSortActive="bin_name" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc" rtlDiv>
              <ng-container matColumnDef="name">
                <th [hidden]="!binNameColAll && usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name">
                {{ 'SITE_MANAGMENT.BIN_NAME' | translate }} </th>
                <td [hidden]="!binNameColAll && usageType == 2" mat-cell *matCellDef="let element">
                  <div class="flex-th-value">
                    @if(user_role_id != 8){
                      <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'collections',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="cut-txt binNameHref"> {{element ? element.bin_name : ''}}</a>
                    }@else{
                      <span title="{{element ? element.bin_name : ''}}" class="cut-txt binNameHref">{{element ? element.bin_name : ''}}</span>
                    }
                    <button (click)="changeSubmitClickedStatus(element)" class="tooltip-btn" type="button">
                      @if (element.number_of_bins_in_cluster > 1 && element.chosenCluster) {
                        <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" />
                      }
                    </button>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="siteName">
                <th [hidden]="!siteNameColAll" mat-header-cell *matHeaderCellDef mat-sort-header="site_name">
                {{ 'TRUCK_MAP.SITE' | translate }} </th>
                <td [hidden]="!siteNameColAll" mat-cell *matCellDef="let element"> <span> {{element ? element.site_name : ''}}</span> </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address">
                {{ 'LIST_COLUMNS.ADDRESS' | translate }} </th>
                <td mat-cell *matCellDef="let element"> <span title="{{element ? element.bin_address : ''}}" class="cut-txt"> {{element ? element.bin_address : ''}}</span> </td>
              </ng-container>
              <ng-container matColumnDef="neighborhood">
                <th [hidden]="!neighborhoodAll" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood">
                {{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</th>
                <td [hidden]="!neighborhoodAll" mat-cell *matCellDef="let element"> {{element ? element.neighborhood: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th [hidden]="!binTypeColAll" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type">
                  {{ 'LIST_COLUMNS.BIN_TYPE' | translate }}
                </th>
                <td [hidden]="!binTypeColAll" mat-cell *matCellDef="let element"> {{element ? element.bin_type: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="clearing">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_collection_time"> {{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element ? element.bin_collection_time: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="volume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_fill_level_before_collection"> {{ 'LIST_COLUMNS.Capacity_Pick_Up' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                  <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapperCollections(element)">
                    <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternalCollections(element)"></div>
                  </div>
                  <div class='table-status-progress-bar-title' rtlDiv>{{element ? element.bin_fill_level_before_collection: ''}}{{capacityDisplaySymbol}}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="cause">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_cause_name"> {{ 'TITLES.COLLECTION_CAUSE' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                  @if (currentLang != 'iw') {
                    <span>{{element ? element.collection_cause_name: ''}}</span>
                  }
                  @if (currentLang == 'iw') {
                    <span>{{element ? element.collection_cause_name_hebrew: ''}}</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_avg_fill_rate"> {{ 'LIVE_INFO.AVG_RATE' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                  {{element ? (element.bin_avg_fill_rate| number:'1.0-2'): ''}}{{capacityDisplaySymbol}}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="collected_driver_first_name"> {{ 'TITLES.DRIVER_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                  @if (currentLang == 'iw') {
                    <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'ללא'}}</span>
                  }
                  @if (currentLang != 'iw') {
                    <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'NA'}}</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="license_plate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="assigned_truck_license_plate">
                  {{ 'TITLES.LICENSE_PLATE' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{element ? element.assigned_truck_license_plate: ''}}
                </td>
              </ng-container>
              <ng-container matColumnDef="plus">
                <th mat-header-cell *matHeaderCellDef rtlDiv>
                  <div class="add-dynamic-items" [matMenuTriggerFor]="menu">
                    <img rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>
                  </div>
                  <mat-menu #menu="matMenu" rtlDiv>
                    <div class="dynamic-item" mat-menu-item rtlDiv>
                      <label class="container-extra" rtlDiv>
                        {{ 'TRUCK_MAP.SITE' | translate }}
                        <input type="checkbox" [checked]="siteNameColAll" [(ngModel)]="isSiteNameChecked" (change)="toggleEditableSiteAll(isSiteNameChecked)" rtlDiv>
                        <span class="checkmark" rtlDiv></span>
                      </label>
                    </div>
                    <div class="dynamic-item" mat-menu-item rtlDiv>
                      <label class="container-extra" rtlDiv>
                        {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}
                        <input type="checkbox" [checked]="neighborhoodAll" [(ngModel)]="isNeighborhoodChecked" (change)="toggleEditableAll(isNeighborhoodChecked)" rtlDiv>
                        <span class="checkmark" rtlDiv></span>
                      </label>
                    </div>
                    <div class="dynamic-item" mat-menu-item rtlDiv>
                      <label class="container-extra" rtlDiv>
                        {{ 'LIST_COLUMNS.BIN_TYPE' | translate }}
                        <input type="checkbox" [checked]="binTypeColAll" [(ngModel)]="isBinTypeColChecked" (change)="toggleEditableTypeAll(isBinTypeColChecked)" rtlDiv>
                        <span class="checkmark" rtlDiv></span>
                      </label>
                    </div>
                    @if (usageType != 1) {
                      <div class="dynamic-item" mat-menu-item rtlDiv>
                        <label class="container-extra" rtlDiv>
                          {{ 'LIST_COLUMNS.NAME' | translate }}
                          <input type="checkbox" [checked]="binNameColAll" [(ngModel)]="isBinNameColChecked" (change)="toggleEditableNameAll(isBinNameColChecked)" rtlDiv>
                          <span class="checkmark" rtlDiv></span>
                        </label>
                      </div>
                    }
                  </mat-menu>
                </th>
                <td mat-cell *matCellDef="let element"></td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div [hidden]="filteredData.length == 0">
              <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="mobile-group-tabs">
        <div class="card-header mobile-header">
          <div class="mobile-flex">
            <span class="mobile-title">  {{ 'TITLES.ALL_COLLECTIONS' | translate }} {{datesDiff}}</span>
          </div>
        </div>
        <div class="card mobile-card">
          <div class="card-body">
            <div class='mobile-search-input-container' rtlDiv>
              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
              <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                id="mobile-collection-table" rtlDiv>
              </div>
              <div class="spinner-border mobile-spinner" [hidden]="!spinnerActive">
                <div class="spinner"></div>
              </div>
              <table rtlDiv class="mobile-table" #table mat-table [dataSource]="getTableData()" multiTemplateDataRows
                matSort matSortStart="asc" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc"
                matSortActive="bin_name" [ngClass]="{'show':!spinnerActive,'hide':spinnerActive}">
                <ng-container matColumnDef="name">
                  <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="bin_name" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' |
                  translate }} </th>
                  <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element"> 
                    <div class="flex-th-value">
                      <span (click)="goToBinLivePage(element)" title="{{element ? element.bin_name : ''}}" class="cut-txt"> {{element ? element.bin_name : ''}}</span> 
                      @if (element.number_of_bins_in_cluster > 1 && element.chosenCluster) {
                        <button (click)="changeSubmitClickedStatus(element)" class="tooltip-btn" type="button">
                          <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" />
                        </button>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="volume">
                  <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="bin_fill_level_before_collection" mat-header-cell *matHeaderCellDef>
                  {{ 'LIST_COLUMNS.Capacity_Pick_Up' | translate }} </th>
                  <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element">
                    <span> {{element.bin_fill_level_before_collection}}%</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="arrowDown">
                  <th class="mobile-th" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <div rtlDiv class="mobile-status-value">
                      @if (expandedElement !== element) {
                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                      }
                      @if (expandedElement === element) {
                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                  <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                    <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="mobile-element-diagram" rtlDiv>
                        <div class="dFlex">
                          <div class="mobile-description-details-title">{{ 'TRUCK_MAP.SITE' | translate }}</div>
                          <div class="mobile-description-details-value"> {{element.site_name}} </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-address-details-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</div>
                          <div class="mobile-address-details-value">
                            <span>{{element.bin_address}}</span>
                          </div>
                        </div>
                        @if (element && (element.neighborhood != null && element.neighborhood != '')) {
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</div>
                            <div class="mobile-description-details-value"> {{element ? element.neighborhood: ''}} </div>
                          </div>
                        }
                        <div class="dFlex">
                          <div class="mobile-description-details-title">{{'LIST_COLUMNS.BIN_TYPE' | translate}}</div>
                          <div class="mobile-description-details-value"> {{element ? element.bin_type: ''}} </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-description-details-title">{{'LIVE_INFO.LIST_COLLECTION_TIME' | translate}}</div>
                          <div class="mobile-description-details-value"> {{element ? parseDateFormat(element.bin_collection_time) : ''}} </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-description-details-title">{{'TITLES.COLLECTION_CAUSE' | translate}}</div>
                          <div class="mobile-description-details-value"> {{ currentLang == 'iw' ? element.collection_cause_name_hebrew : element.collection_cause_name}} </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-description-details-title">{{'LIVE_INFO.AVG_RATE' | translate}}</div>
                          <div class="mobile-description-details-value"> {{element ? (element.bin_avg_fill_rate| number:'1.0-2'): ''}}{{capacityDisplaySymbol}} </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-acre-details-title">{{'TITLES.DRIVER_NAME' | translate}}</div>
                          <div class="mobile-acre-details-value"> {{element.collected_driver_first_name != null ? element.collected_driver_first_name + " " + element.collected_driver_last_name : " "}} </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-description-details-title">{{'TITLES.LICENSE_PLATE' | translate}}</div>
                          <div class="mobile-description-details-value"> {{ element ? element.assigned_truck_license_plate : ''}} </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                  (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
              </table>
              <div [hidden]="filteredData.length == 0">
                <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    }