@if (!isMobile) {
  <div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-modal-title" rtlDiv>
      <div class="dflex" (click)="closeModal()">
        <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
      </div>
      <div>
        <span class="main-title">{{ 'TITLES.MANAGE_COL_TITLE' | translate }}</span>
      </div>
    </div>
    <form class="dynamic-options" [formGroup]="InventoryForm">
      @if (usageType == 1) {
        <mat-checkbox formControlName="isBinNameChecked">{{ 'LIST_COLUMNS.NAME' | translate }}</mat-checkbox>
      }
      <mat-checkbox formControlName="isAddressChecked">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</mat-checkbox>
      @if (usageType == 1) {
        <mat-checkbox formControlName="isBinDescChecked">{{ 'LIST_COLUMNS.DESCRIPTION' | translate }}</mat-checkbox>
      }
      @if (usageType == 1) {
        <mat-checkbox formControlName="isLastCollectionChecked">{{ 'LIST_COLUMNS.LAST_COLLECTION' | translate }}</mat-checkbox>
      }
      @if (usageType == 2) {
        <mat-checkbox formControlName="isLastRefillChecked">{{ 'LIST_COLUMNS.LAST_REFILL' | translate }}</mat-checkbox>
      }
      <mat-checkbox formControlName="isLastUpdateChecked">{{ 'LIST_COLUMNS.LAST_UPDATE' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isStatusChecked">{{ 'LIST_COLUMNS.STATUS' | translate }}</mat-checkbox>
      @if (usageType == 1) {
        <mat-checkbox formControlName="isNeighborhoodChecked">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</mat-checkbox>
      }
      @if (usageType == 2) {
        <mat-checkbox formControlName="isBinNameColChecked">{{ 'LIST_COLUMNS.NAME' | translate }}</mat-checkbox>
      }
      <mat-checkbox formControlName="isBinSiteColChecked">{{ 'TRUCK_MAP.SITE' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isBinIdColChecked">{{ 'LIST_COLUMNS.ID' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isBinTypeColChecked">
        @if (usageType == 1) {
          <span>{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</span>
        }
        @if (usageType == 2) {
          <span>{{ 'LIST_COLUMNS.TANK_TYPE' | translate }}</span>
        }
      </mat-checkbox>
      <mat-checkbox formControlName="isBinInstallDateColChecked">{{ 'LIST_COLUMNS.INSTALLATION_DATE' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isBinAvgRateColChecked">
        @if (usageType == 1) {
          <span>{{ 'LIVE_INFO.EMPTY_RATE' | translate }}</span>
        }
        @if (usageType == 2) {
          <span>{{ 'FILLINGS.AVG_DAILY_RATE' | translate }}</span>
        }
      </mat-checkbox>
      @if (usageType == 1) {
        <mat-checkbox formControlName="isBinAvgUsageRateChecked">{{ 'LIVE_INFO.AVG_RATE' | translate }}</mat-checkbox>
      }
      @if (usageType == 1) {
        <mat-checkbox formControlName="isBinCollectionFrequencyChecked">{{ 'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate }}</mat-checkbox>
      }
      @if (usageType == 2) {
        <mat-checkbox formControlName="isAvgDailyRateDunamColChecked">{{'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }}</mat-checkbox>
      }
      <mat-checkbox formControlName="isBinCollectionModeChecked">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isBinDeviceIdColChecked">{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}</mat-checkbox>
    </form>
    <form class="dynamic-options" [formGroup]="CollectionForm">
      @if (usageType == 1) {
        <mat-checkbox formControlName="isBinNameChecked">{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}</mat-checkbox>
      }
      <mat-checkbox formControlName="isAddressChecked">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isTotalCollectionsChecked">{{ 'TITLES.ACTUAL_CLEARING' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isAvgCapAtPickUpChecked">{{ 'TITLES.AVERAGE_CAP_AT_PICK' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isSiteNameChecked">{{ 'TRUCK_MAP.SITE' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isNeighborhoodChecked">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isBinTypeColChecked">{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</mat-checkbox>
      @if (usageType == 2) {
        <mat-checkbox formControlName="isBinNameColChecked">{{ 'LIST_COLUMNS.NAME' | translate }}</mat-checkbox>
      }
    </form>
    <form class="dynamic-options" [formGroup]="TicketManagementForm">
      <mat-checkbox formControlName="isTicketIdChecked">{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isTicketStatusChecked">{{ 'TICKET_MANAGEMENT.STATUS' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isTicketCauseChecked">{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</mat-checkbox>
      @if (user_role_id != 3 && user_role_id != 4) {
        <mat-checkbox formControlName="isTicketPriorityChecked">{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</mat-checkbox>
      }
      <mat-checkbox formControlName="isTicketSiteChecked">{{ 'TICKET_MANAGEMENT.SITE' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isBinNameChecked">{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isTicketAddressChecked">{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isDescChecked">{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isReporterChecked">{{ 'TICKET_MANAGEMENT.REPORTER' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isTicketCreatedChecked">{{ 'TICKET_MANAGEMENT.CREATED' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isArrivalDateChecked">{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</mat-checkbox>
      <mat-checkbox formControlName="isTicketClosedChecked">{{ 'TICKET_MANAGEMENT.CLOSED' | translate }}</mat-checkbox>
    </form>
    <div class="bottom-page">
      <div class="info-row">
        <img class="cursor-pointer" src="assets/images/topmenu/info.svg" alt=""/>
        <span>{{ 'TITLES.MANAGE_COL' | translate }}</span>
      </div>
      <div class='main-btns-wrapper' rtlDiv>
        <div class='btns-container' rtlDiv>
          <div class="mobile-cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</div>
          <div class="mobile-apply-btn" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
}