import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslatorService } from '../../services/translator_service';
import { ApiQuery } from '../../web-services/api/api.query';
import { Router, RouterModule } from '@angular/router';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiService } from '../../web-services/api/api.service';
import _ from 'lodash';
import moment from 'moment';
import { Papa } from 'ngx-papaparse';
import { UtilService } from '../../services/util.service';
import { TableUtil } from '../../tableUtil';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-bin-list-view',
  standalone: true,
  imports: [MatCardModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatTableModule,MatSortModule,CommonModule,
	PaginationComponent,FormsModule,RouterModule],
  templateUrl: './bin-list-view.component.html',
  animations: [
	trigger('detailExpand', [
	  state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
	  state('expanded', style({height: '*'})),
	  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
	]),
  ],
  styleUrl: './bin-list-view.component.scss'
})
export class BinListViewComponent {
	isBinDeviceIdColChecked: any;
	isBinTypeColChecked: any;
	isBinIdColChecked: any;
	isBinNameColChecked: any;
	isNeighborhoodChecked: any;
	expandedElement: any;	
  isMobile: boolean = false;
	showlist:boolean = false;
	showRowDetails:boolean=false;
	mobileCardOpen:boolean=false;
	@Output() showCMP = new EventEmitter<boolean>(true);
	@Input() showMap: boolean = false;
	@Input() orientation: boolean = false;
	@Input() showCollection : boolean = false;
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	csvData = '';
	displayedColumns = ['name', 'id','device_id','type', 'address','neighborhood', 'cluster_id','description','last_refill', 'last_collection', 'status','plus'];
	columnsToDisplay = ['name','cluster_id', 'status','arrowDown'];
	startIndexTableData = 0;
	endIndexTableData = 5;
	binsData:any;
	dataTableLength = 0;
	dataSource:any = new MatTableDataSource<any>();
	tempFilter = '';
	translationsObj:any;
	capacityDisplayType: number = 0;
	filteredExist:boolean = false;
	spinnerExist : boolean = false;
	neighborhood:boolean = false;
	binIdCol:boolean = false;
	binNameCol:boolean = false;
	binDeviceIdCol:boolean = false;
	binTypeCol:boolean = false;
	spinnerActive:boolean = true;
	usageType = 1;
	currentLang:any;
	filteredData:Array<object> = [];

	constructor(private translator: TranslatorService, private papa: Papa,
		private utilService: UtilService, private router: Router,
		private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
		private apiService:ApiService) {}

	sortData(sort: Sort) {
		const data = this.binsData.slice();
		if (!sort.active || sort.direction === '') {
			this.binsData = data;
			return;
		}
		this.binsData = data.sort((a:any, b:any) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	compare(a:any, b:any, isAsc:any) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

 
	closeCMP(){
		this.showCMP.emit(false);
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		
		this.apiService?.GetDynamicColumns()?.subscribe((column:any) => {  			
			if(column.DynamicColumns != null && column.DynamicColumns.length > 0){
				_.each(column.DynamicColumns, (item) => {	
					if(item.is_activate_for_current_user){
						this.showColumn(item["dynamic_column_id"].toString());
					}				
				});
			}				
		});

		this.apiQuery.user$.subscribe((user:any) => {
			if (!user) return;
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.capacityDisplayType = user["user_default_capacity_type_id"];

			this.apiQuery.filteredBasicBins$.subscribe(bins => {
				let binsFiltered;	
				this.spinnerActive = true;
				if(bins.length == 0)return;
				this.spinnerActive = false;

				this.translator.currentLangEmitter$
				.subscribe(async value=>{						
				  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				  this.currentLang = value;	
				  _.each(bins, (bin:any) => {
					if(this.usageType == 1){
						bin["calcDiff_lastCollection"] = this.calcDiff(bin["Bin_live_Data"].last_collection_time);
					}else{
						bin["calcDiff_lastRefill"] = this.calcDiff(bin["Bin_live_Data"].last_collection_time);
					}
				  });
				  if(this.usageType == 1){
					binsFiltered = _.map(bins, (b:any) => {
						return {
							bin_name: b["bin_name"],
							bin_id: b["bin_id"],
							bin_type:b["bin_type_name"],
							address: b["Bin_location"]["bin_address"],
							neighborhood:b["Bin_location"]["bin_neighbourhood"],
							description: b["bin_description"],
							last_collection: b["Bin_live_Data"]["last_collection_time"],
							status: b["Bin_live_Data"]["fill_level_percent"],
							green_status_fill_level_in_percent: b["Bin_thresholds"].green_status_fill_level_in_percent,
							yellow_status_fill_level_in_percent: b["Bin_thresholds"].yellow_status_fill_level_in_percent,
							red_status_fill_level_in_percent: b["Bin_thresholds"].red_status_fill_level_in_percent,
							bin_max_volume_in_liters: b["Bin_thresholds"].bin_max_volume_in_liters,
							bin_capacity_to_display: this.capacityDisplayType != 0 ? (b["Bin_live_Data"].fill_level_percent * b["Bin_thresholds"].bin_max_capacity_in_liters / 100) + "%" : b["Bin_live_Data"].fill_level_percent + "%",
							bin_color : b["Bin_live_Data"]["color_status_name"],
							cluster_id : b["cluster_id"],
							device_id: b["device_id"],
							calcDiff_lastCollection: b["calcDiff_lastCollection"]
						};
					});
				}else{
					binsFiltered = _.map(bins, (b:any) => {
						return {
							bin_name: b["bin_name"],
							bin_id: b["bin_id"],
							bin_type:b["bin_type_name"],
							address: b["Bin_location"]["bin_address"],
							neighborhood:b["Bin_location"]["bin_neighbourhood"],
							description: b["bin_description"],
							last_refill: b["Bin_live_Data"]["last_collection_time"],
							bin_capacity_to_display: this.capacityDisplayType != 0 ? b["Bin_live_Data"].fill_level_percent * b["Bin_thresholds"].bin_max_capacity_in_liters / 100 + "L" : b["Bin_live_Data"].fill_level_percent + "L",
							green_status_fill_level_in_percent: b["Bin_thresholds"].green_status_fill_level_in_percent,
							yellow_status_fill_level_in_percent: b["Bin_thresholds"].yellow_status_fill_level_in_percent,
							red_status_fill_level_in_percent: b["Bin_thresholds"].red_status_fill_level_in_percent,
							bin_max_volume_in_liters: b["Bin_thresholds"].bin_max_volume_in_liters,
							status: b["Bin_live_Data"]["fill_level_percent"],
							bin_color : b["Bin_live_Data"]["color_status_name"],
							cluster_id : b["cluster_id"],
							device_id: b["device_id"],
							calcDiff_lastRefill: b["calcDiff_lastRefill"]
						};
					});
				}
	
				this.binsData = binsFiltered;								
				this.dataTableLength = this.binsData.length;
				this.dataSource = new MatTableDataSource<any>(binsFiltered);
	
				if(this.isMobile){					
					if(this.dataSource.filteredData.length > 0){
						this.spinnerExist = true;
						this.filteredExist = true;
					}else{
						this.spinnerExist = false;			
						this.filteredExist = false;
					}
				}
				if(this.usageType == 1){
					this.sortData({ direction: 'desc', active: 'bin_name' });
				}else{
					this.sortData({ direction: 'asc', active: 'cluster_id' });
				}	
			  });
			});
		});
	}

	
	showColumn(value:any){
		switch(value) {
			case '1':
				this.neighborhood = true;
			  break;
			case '2':
				this.binIdCol = true;
			  break;
			case '3':
				this.binTypeCol = true;
				break;	
			case '6':
				this.binNameCol = true;
				break;	
			case '7':
				this.binDeviceIdCol = true;
				break;
		  }
	}

	setColumnForUser(id:any,command:any){
		let dataToSend:any = {};
		if(command){
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 1;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}else{
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 0;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}		
	}

	ngAfterViewInit(){
		this.dataSource.sort = this.sort;
	}

	toggleEditable(value:any) {				
		this.neighborhood = value;
		this.setColumnForUser("1",this.neighborhood);
   }

   toggleEditableId(value:any){
	   this.binIdCol = value;
	   this.setColumnForUser("2",this.binIdCol);
   }

   toggleEditableName(value:any){
	this.binNameCol = value;
	this.setColumnForUser("6",this.binNameCol);
   }

   toggleEditableDeviceId(value:any){
	this.binDeviceIdCol = value;
	this.setColumnForUser("7",this.binDeviceIdCol);
   }

   toggleEditableType(value:any){
	this.binTypeCol = value;
	this.setColumnForUser("3",this.binTypeCol);
   }

	parseDateFormat(dateStr:any){
		return this.currentLang == 'iw' ? this.utilService.convertDateHebrew(dateStr) : this.utilService.convertDateEnglish(dateStr);
	}

	openDetails(){
		this.mobileCardOpen = true;
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	  displayList(){		  
		this.showlist = !this.showlist;
	  }

	  getTableData = () => {
		
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.binsData);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);	
		this.dataSource["AllData"] = new MatTableDataSource<any>(this.binsData);
		return this.dataSource;
	}

	onPageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
	}

	goToBinLivePage(bin: any) {
		this.router.navigate(['/binLiveInfoPage'], { queryParams: { pageName: 'dashboard', binId: bin.bin_id } });
	}

	calcDiff = (date: string) => {
		if (this.translationsObj && date) {
			let diff_in_string = '';
			const original_date = moment(date);
			const date_time_now = moment();
			const diff_in_object: any = this.utilService.preciseDiffBetweenDates(original_date, date_time_now, true);
			if (diff_in_object.months > 0) {
				diff_in_string = diff_in_string + diff_in_object.months + ' ';
				if (diff_in_object.months === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTH + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTHS + ' ';
				}
			}
			if (diff_in_object.days > 0) {
				diff_in_string = diff_in_string + diff_in_object.days + ' ';
				if (diff_in_object.days === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAY + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAYS + ' ';
				}
			}
			if (diff_in_object.hours > 0) {
				if (diff_in_object.days > 0) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.hours + ' ';
				} else {
					diff_in_string += diff_in_object.hours + ' ';
				}
				if (diff_in_object.hours === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOUR + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOURS + ' ';
				}
			}
			
			if (diff_in_object.minutes > 0) {
				if (diff_in_object.hours > 0) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.minutes + ' ';
				} else {
					diff_in_string += diff_in_object.minutes + ' ';
				}
				if (diff_in_object.minutes === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTE + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTES + ' ';
				}
			}
			else {
				diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTE + ' ';
			}

			if (this.currentLang !== 'iw') {
				diff_in_string += this.translationsObj.COLLECTION_REPORT.AGO;
			}
			else {
				diff_in_string = this.translationsObj.COLLECTION_REPORT.AGO + ' ' + diff_in_string;
			}
			
			return diff_in_string;
		}
		
	}

	exportToCsv() {
		let dataToExport = null;
		dataToExport = _.cloneDeep(this.binsData);
		_.each(dataToExport, (item) => {
			item.status += '%';
		});

		const header :any = this.usageType == 1 ? 
		{
			'0' : this.translationsObj.LIST_COLUMNS.NAME, 
			'1' : this.translationsObj.LIST_COLUMNS.ID,
			'2' : this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
			'3' : this.translationsObj.LIST_COLUMNS.ADDRESS,
			'4' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
			'5' : this.translationsObj.LIST_COLUMNS.DESCRIPTION, 
			'6' : this.translationsObj.LIST_COLUMNS.LAST_COLLECTION, 
			'7' : this.translationsObj.LIST_COLUMNS.STATUS
		} :
		{
			'0' :this.translationsObj.LIST_COLUMNS.NAME, 
			'1' :this.translationsObj.LIST_COLUMNS.ID,
			'2' :this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
			'3' :this.translationsObj.LIST_COLUMNS.ADDRESS,
			'4' :this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
			'5' :this.translationsObj.LIST_COLUMNS.DESCRIPTION,
			'6' :this.translationsObj.LIST_COLUMNS.LAST_REFILL, 
			'7' :this.translationsObj.LIST_COLUMNS.STATUS
		};

		const flatData = dataToExport.reduce((b:any, a:any) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])
		
		let source = {
			flatData,
			header
		};	

		let result = source.flatData.map((details:any) => 
		Object.keys(details).reduce((a:any, b:any) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "bins");
		}else{
			TableUtil.exportArrayToExcel(result, "מיכלים");
		}
	}

	applyFilter = (filterValue: any) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
		setTimeout(() => {
			if(this.isMobile){
				if(this.dataSource.data.length == 0){
					this.filteredExist = false;
				}else{
					this.filteredExist = true;
				}
			}
		}, 0)	
	}

	getProgressBarStyleWrapper = (binInfo: any) => {
		if (binInfo.bin_color == "GREEN") {
			return { 'background-color': 'rgba(52, 208, 141, 0.15)' };
		} else if (binInfo.bin_color == "YELLOW") {
			return { 'background-color': 'rgba(226, 224, 85, 0.15)' };
		}
		return { 'background-color': 'rgba(215, 89, 76, 0.15)' };
		
	}


	getProgressBarStyleInternal = (binInfo: any) => {
		let capacityVolume = binInfo.status;
		if(!this.isMobile){
			if (binInfo.bin_color == "GREEN") {
				return { 'background-color': '#34D08D', 'width': capacityVolume + '%' ,'max-width':'100%'};
			} else if (binInfo.bin_color == "YELLOW") {
				return { 'background-color': '#E2E055', 'width': capacityVolume + '%','max-width':'100%' };
			}
			return { 'background-color': '#D7594C', 'width': capacityVolume + '%','max-width':'100%' };
		}else{
			if (binInfo.bin_color == "GREEN") {
				return { 'color': '#34D08D'};
			} else if (binInfo.bin_color == "YELLOW") {
				return { 'color': '#E2E055'};
			}
			return { 'color': '#D7594C'};
		}
	}
}
