import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild, ViewRef } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import _ from 'lodash';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import moment from 'moment';
import { TableUtil } from '../../../tableUtil';
import { UtilService } from '../../../services/util.service';
import { Router, RouterModule } from '@angular/router';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { Papa } from 'ngx-papaparse';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { PaginationComponent } from '../../pagination/pagination.component';

@Component({
  selector: 'app-truck-workplans-list-view',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,MatCardModule,MatDividerModule,
    MatTableModule,MatSortModule,FormsModule,PaginationComponent,RouterModule],
  templateUrl: './truck-workplans-list-view.component.html',
  animations: [
		trigger('detailExpand', [
		  state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
		  state('expanded', style({height: '*'})),
		  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	  ],
  styleUrl: './truck-workplans-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruckWorkplansListViewComponent {
  expandedElement: any;
  isBinTypeColChecked:any;
  isBinIdColChecked:any;
  isBinNameColChecked:any;
  isNeighborhoodChecked:any;
  isMobile: Boolean;
	exceptions: any;
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	exceptionsSub: any;
	spinnerActive : boolean = true;
	@Input() chosenTruck:object;
	@Input() eventSelectedManager : any;
	displayedColumns = ['CollectionOrder', 'name', 'id','type','address','neighborhood','beforeCollectionCapacityInPercent','afterCollectionCapacityInPercent', 'status', 'last_collection', 'collected','plus'];
	columnsToDisplay = ['CollectionOrder','name', 'collected','arrowDown'];
	truckBins: any;
	startIndexTableData = 0;
	endIndexTableData = 5;
	binsData;
	dataTableLength = 0;
	dataSource = new MatTableDataSource<any>();
	tempFilter = '';
	neighborhood:boolean = false;
	binIdCol:boolean = false;
	binNameCol:boolean = false;
	binOrderCol:boolean = true;
	binTypeCol:boolean = false;
	currentLang;
	translationsObj;
	usageType = 1;
	capacityDisplayType: number = 0;
	capacitySymbol: string = ' %';
	dateOfPlan;
	isPast : boolean = false;
	filteredData:Array<object> = [];
	currentSitePlanId: number;
	currentTabletId: number = -1; 
	user_role_id = 0;

	constructor(private utilService: UtilService, private router: Router, private translator: TranslatorService, 
		private responsiveService: ResponsiveService,private papa: Papa,private apiService:ApiService,
		private apiQuery:ApiQuery,private cd: ChangeDetectorRef) {
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currentLang = value;
		})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.apiService.GetDynamicColumns().subscribe((column:any) => {  			
			if(column.DynamicColumns != null && column.DynamicColumns.length > 0){
				_.each(column.DynamicColumns, (item) => {	
					if(item.is_activate_for_current_user){
						this.showColumn(item["dynamic_column_id"].toString());
					}				
				});
			}				
		});

		this.apiQuery.user$.subscribe(user => {
			if (!user) return;
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.capacityDisplayType = user["user_default_capacity_type_id"];
			if (this.capacityDisplayType != 0) {
				this.capacitySymbol = 'L';
			}
			this.user_role_id = user["user_role_id"];
		});
	}

	
	ngOnDestroy() {
		if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	  }

	ngOnChanges(){
		if(this.chosenTruck && this.chosenTruck["Bins_collection_info"] && this.eventSelectedManager){	
			if(this.eventSelectedManager.Vehicle_info.some(vehicle => vehicle.tablet_id == this.chosenTruck["tablet_id"] && vehicle.time_of_planned_departure == this.chosenTruck["time_of_planned_departure"])){
				this.dateOfPlan = this.eventSelectedManager.actual_plan_start_time ? this.eventSelectedManager.actual_plan_start_time :  this.eventSelectedManager.plan_start_time;
				if(moment(moment().format("YYYY-MM-DD 00:00:00")).valueOf() > moment(moment(this.dateOfPlan).format("YYYY-MM-DD 00:00:00")).valueOf()){
					this.isPast = true;
				}else{
					this.isPast = false;
				}
				this.getExceptions();	
			}								
		}
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	async getExceptions(){
		let totalRelevantBins = [];
		_.each(this.chosenTruck["Bins_collection_info"], (bin) => {
			totalRelevantBins.push(bin);
		});		
		this.currentTabletId = this.chosenTruck["tablet_id"];
		this.currentSitePlanId = this.getSitePlanByTabletId(this.currentTabletId);
		this.exceptionsSub = await this.apiService.exceptionHandler(this.currentSitePlanId,this.currentTabletId);
		this.exceptions = this.exceptionsSub.exceptionInfo;	
		this.exceptions = [];
		this.subscribeTruckWorkplan(totalRelevantBins,this.eventSelectedManager);																					
	}

	
	getSitePlanByTabletId(tabletId) {
		let res = -1;

		if (this.eventSelectedManager != undefined) {
			_.each(this.eventSelectedManager.Vehicle_info, (vehicle) => {
				if (vehicle.tablet_id == tabletId) {
					res = this.eventSelectedManager.Site_plan_id;
				}
			});
		}

		return res;
	}

	showColumn(value){
		switch(value) {
			case '1':
				this.neighborhood = true;
			  break;
			case '2':
				this.binIdCol = true;
			  break;
			case '3':
				this.binTypeCol = true;
				break;	
			case '6':
				this.binNameCol = true;
				break;	
			default:
		  }
	}

	setColumnForUser(id,command){
		let dataToSend = {};
		if(command){
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 1;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}else{
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 0;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}		
	}

	toggleEditable(value) {
		this.neighborhood = value;
		this.setColumnForUser("1",this.neighborhood);
   }

   toggleEditableId(value){
	   this.binIdCol = value;
	   this.setColumnForUser("2",this.binIdCol);
   }

   toggleEditableName(value){
	this.binNameCol = value;
	this.setColumnForUser("6",this.binNameCol);
   }

   toggleEditableType(value){
	this.binTypeCol = value;
	this.setColumnForUser("3",this.binTypeCol);
   }

	private subscribeTruckWorkplan = (truckWorkPlanBins,workplan) => {
		this.truckBins = truckWorkPlanBins;
		this.initializeBinsTable(this.truckBins,workplan);
	}

	getCollectionImgForMobile(elm){	
		if(elm == null){
			return '';
		}

		if(elm){
			if(elm.collected == 1){
				return "assets/images/workplans-page/bin_collected.svg";
			}else{
				if(elm.bin_status_id_color == 'RED'){
					return "assets/images/workplans-page/red_not_collected.svg";
				}else if(elm.bin_status_id_color == 'YELLOW'){
					return "assets/images/workplans-page/yellow_not_collected.svg";
				}else{
					return "assets/images/workplans-page/green_not_collected.svg";
				}
			}				
		}
	}

	getImg(elm){	
		if(elm == null){
			return '';
		}

		if(elm){
			if(elm.collected == 2){
				return "assets/images/dashboard/collected2.svg";
			}				
		}

		if(elm && this.eventSelectedManager && this.exceptions){				
			if(elm.collected){
				if(elm.exceptions.length > 0){
					let binObj = _.find(elm.exceptions, function (exception) {
						return (exception.exception_id == 7)
					});	
					if(binObj != null){							
						return "assets/images/dashboard/selected.svg";
					}else{
						return "assets/images/dashboard/missed.svg";
					}
				}else{
					return "assets/images/dashboard/selected.svg";
				}
			}else if(new Date(moment(this.eventSelectedManager.finish).format("YYYY-MM-DD HH:mm:ss")).getTime() < new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() && !elm.collected){				
				if(this.exceptions.length == 0){
					return "assets/images/dashboard/notSelected.svg";
				}else{
					if(elm.exceptions.length > 0){
						let binObj = _.find(elm.exceptions, function (exception) {
							return (exception.exception_id == 7)
						});	
						if(binObj != null){							
							return "assets/images/dashboard/collected2.svg";
						}else{
							return "assets/images/dashboard/missed.svg";
						}
					}else{
						return "assets/images/dashboard/notSelected.svg";
					}
				}	
			}else if(new Date(moment(this.eventSelectedManager.finish).format("YYYY-MM-DD HH:mm:ss")).getTime() >= new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime() && !elm.collected){
				if(this.exceptions.length == 0){
					return "assets/images/dashboard/notSelected-old.svg";
				}else{						
					if(elm.exceptions.length > 0){
						let binObj = _.find(elm.exceptions, function (exception) {
							return (exception.exception_id == 7)
						});	
						if(binObj != null){							
							return "assets/images/dashboard/collected2.svg";
						}else{
							return "assets/images/dashboard/missed.svg";
						}
					}else{
						return "assets/images/dashboard/notSelected-old.svg";
					}
				}	
			}
		}		
	}

	private initializeBinsTable = (truckBins,workplan) => {
		let binsFiltered= [];
		binsFiltered = _.map(truckBins, b => {
			let binObj = this.exceptions.filter(function (exception) {
				return (exception.expected_to_collect_bin_id == b.bin_id
					|| exception.bin_id == b.bin_id)
			});	

			let orderedObj = this.truckBins.filter(function (bin) {
				return (bin.bin_collection_order_index == 0)
			});

			if(orderedObj.length == truckBins.length){
				this.binOrderCol = false;
				return {
					bin_name: b.bin_name,
					bin_id: b.bin_id,
					bin_type: b.bin_type_name,
					address: b.Bin_location.bin_address,
					neighborhood:b.Bin_location.bin_neighbourhood,
					beforeCollectionCapacityInPercent:b.beforeCollectionCapacityInPercent,
					afterCollectionCapacityInPercent:b.afterCollectionCapacityInPercent,
					status: b.Last_updated_fill_level_sample.fill_level_in_precent,
					last_collection: b.last_collection_time,
					collected: b.is_collected,
					bin_status_id: b.bin_status_id,
					bin_status_id_color: b.bin_status_id_color,
					bin_capacity_to_display: this.capacityDisplayType != 0 ? b.Last_updated_fill_level_sample.fill_level_in_litters: b.Last_updated_fill_level_sample.fill_level_in_precent,
					removed_bin_reason_type_name_hebrew : b.removed_bin_reason_type_name_hebrew ? b.removed_bin_reason_type_name_hebrew : '',
					removed_bin_reason_type_name : b.removed_bin_reason_type_name ? b.removed_bin_reason_type_name : '',
					exceptions : binObj != null && binObj.length > 0 ? binObj : [],
					exception_id_name : binObj != null && binObj.length > 0 ? binObj[0].exception_id_name.replace(/_/g, ' ') : '',
					exception_id_name_hebrew : binObj != null && binObj.length > 0 ? binObj[0].exception_id_name_hebrew.replace(/_/g, ' ') : '',
					bin_collection_cause_id: b.bin_collection_cause_id,
					removed_bin_reason_type_id:b.removed_bin_reason_type_id ? b.removed_bin_reason_type_id : '',
					plan_collection_time: b.plan_collection_time
				};
			}else{
				this.binOrderCol = true;
				return {
					bin_order : b.bin_collection_order_index,
					bin_name: b.bin_name,
					bin_id: b.bin_id,
					bin_type: b.bin_type_name,
					address: b.Bin_location.bin_address,
					neighborhood:b.Bin_location.bin_neighbourhood,
					beforeCollectionCapacityInPercent:b.beforeCollectionCapacityInPercent,
					afterCollectionCapacityInPercent:b.afterCollectionCapacityInPercent,
					status: b.Last_updated_fill_level_sample.fill_level_in_precent,
					last_collection: b.last_collection_time,
					collected: b.is_collected,
					bin_status_id: b.bin_status_id,
					bin_status_id_color: b.bin_status_id_color,
					bin_capacity_to_display: this.capacityDisplayType != 0 ? b.Last_updated_fill_level_sample.fill_level_in_litters: b.Last_updated_fill_level_sample.fill_level_in_precent,
					removed_bin_reason_type_name_hebrew : b.removed_bin_reason_type_name_hebrew ? b.removed_bin_reason_type_name_hebrew : '',
					removed_bin_reason_type_name : b.removed_bin_reason_type_name ? b.removed_bin_reason_type_name : '',
					exceptions : binObj != null && binObj.length > 0 ? binObj : [],
					exception_id_name : binObj != null && binObj.length ? binObj[0].exception_id_name.replace(/_/g, ' ') : '',
					exception_id_name_hebrew : binObj != null && binObj.length ? binObj[0].exception_id_name_hebrew.replace(/_/g, ' ') : '',
					bin_collection_cause_id: b.bin_collection_cause_id,
					removed_bin_reason_type_id:b.removed_bin_reason_type_id ? b.removed_bin_reason_type_id : '',
					plan_collection_time: b.plan_collection_time
				};
			}
		});
		this.binsData = binsFiltered;
		this.dataTableLength = this.binsData.length;
		this.dataSource = new MatTableDataSource<any>(binsFiltered);
		this.dataSource.sort = this.sort;
		this.sortData({ direction: 'desc', active: 'bin_name' });

		this.spinnerActive = false;
		this.cd.detectChanges();
	}

	public onPageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
	}

	applyFilter = (filterValue) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
	}

	public goToBinLivePage(bin: any) {
		if(this.user_role_id != 8){	
			this.router.navigate(['/binLiveInfoPage'], { queryParams: { binId: bin.bin_id } });
		}
	}

	getExcName(value){
		return value.replace(/_/g, ' ');
	}
	
	parseDateFormat(dateStr){
		return this.currentLang == 'iw' ? this.utilService.convertDateHebrew(dateStr) : this.utilService.convertDateEnglish(dateStr);
	}

	public exportToCsv = () => {
		let dataToExport = null;
		dataToExport = _.cloneDeep(this.binsData);

		_.each(dataToExport, (item) => {	
			if(item.exceptions.length > 0){
				let binObj = _.find(item.exceptions, function (exception) {
					return (exception.exception_id == 7)
				});	
				if (item.collected == 1) {
					item.collected = this.translationsObj.WORKPLAN.COLLECTED;
				}else if(binObj != null){
					if(this.currentLang == 'iw'){
						item.collected = this.getExcName(binObj.exception_id_name_hebrew);
					}else{
						item.collected = this.getExcName(binObj.exception_id_name);
					}
				}
				else if(item.collected == 0){
					item.collected = this.translationsObj.WORKPLAN.NOT_COLLECTED;
				}else if(item.collected == 2){
					if(this.currentLang == 'iw'){
						item.collected = this.translationsObj.WORKPLAN.SKIPPED_BIN + "," + item.removed_bin_reason_type_name != '' ? item.removed_bin_reason_type_name : item.exception_id_name;
					}else{
						item.collected = this.translationsObj.WORKPLAN.SKIPPED_BIN + "," + item.removed_bin_reason_type_name_hebrew != '' ? item.removed_bin_reason_type_name_hebrew : item.exception_id_name_hebrew;
					}
				}
			}else if (item.collected == 1) {
				item.collected = this.translationsObj.WORKPLAN.COLLECTED;
			}
			else if(item.collected == 0){
				item.collected = this.translationsObj.WORKPLAN.NOT_COLLECTED;
			}else if(item.collected == 2){
				if(this.currentLang != 'iw'){
					item.collected = this.translationsObj.WORKPLAN.SKIPPED_BIN + "," + item.removed_bin_reason_type_name != '' ? item.removed_bin_reason_type_name : item.exception_id_name;
				}else{
					item.collected = this.translationsObj.WORKPLAN.SKIPPED_BIN + "," + item.removed_bin_reason_type_name_hebrew != '' ? item.removed_bin_reason_type_name_hebrew : item.exception_id_name_hebrew;
				}
			}
			item.status += '%';
		});

		const header = this.binOrderCol ? 
		{
			'0' : this.translationsObj.WORKPLAN.COLLECTION_ORDER,
			'1' : this.translationsObj.LIST_COLUMNS.NAME, 
			'2' : this.translationsObj.LIST_COLUMNS.ID,
			'3' : this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
			'4' : this.translationsObj.LIST_COLUMNS.ADDRESS,
			'5' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
			'6' : this.translationsObj.WORKPLAN.CAP_AT_PICKUP,
			'7' : this.translationsObj.WORKPLAN.CAP_AFTER_PICKUP,
			'8' : this.translationsObj.LIST_COLUMNS.STATUS,
			'9' : this.translationsObj.LIST_COLUMNS.LAST_COLLECTION, 
			'10' : this.translationsObj.LIST_COLUMNS.COLLECTED
		} :
		{
			'0' : this.translationsObj.LIST_COLUMNS.NAME, 
			'1' : this.translationsObj.LIST_COLUMNS.ID,
			'2' : this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
			'3' : this.translationsObj.LIST_COLUMNS.ADDRESS,
			'4' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
			'5' : this.translationsObj.WORKPLAN.CAP_AT_PICKUP,
			'6' : this.translationsObj.WORKPLAN.CAP_AFTER_PICKUP,
			'7' : this.translationsObj.LIST_COLUMNS.STATUS,
			'8' : this.translationsObj.LIST_COLUMNS.LAST_COLLECTION, 
			'9' : this.translationsObj.LIST_COLUMNS.COLLECTED
		};

		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])
		
		let source = {
			flatData,
			header
		};	

		let result = source.flatData.map((details) => 
		Object.keys(details).reduce((a, b) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "Daily Workplan");
		}else{
			TableUtil.exportArrayToExcel(result, "תוכנית עבודה יומית");
		}
	}

	public getProgressBarStyleWrapper = (binInfo: any) => {
		if (!binInfo) { return; }
		if (binInfo.bin_status_id_color === 'GREEN') {
			return { 'background-color': 'rgba(52, 208, 141, 0.15)' };
		}
		else if (binInfo.bin_status_id_color === 'YELLOW') {
			return { 'background-color': 'rgba(226, 224, 85, 0.15)' };
		}
		else {
			return { 'background-color': 'rgba(215, 89, 76, 0.15)' };
		}
	}

	public getProgressBarStyleInternal = (binInfo: any) => {
		if (!binInfo) { return; }
		if (binInfo.bin_status_id_color === 'GREEN') {
			return { 'background-color': '#34D08D', 'width': binInfo.status + '%' ,'max-width':'100%'};
		}
		else if (binInfo.bin_status_id_color === 'YELLOW') {
			return { 'background-color': '#E2E055', 'width': binInfo.status + '%','max-width':'100%' };
		}
		else {
			return { 'background-color': '#D7594C', 'width': binInfo.status + '%','max-width':'100%' };
		}
	}

	getTableData = () => {
		if (this.dataSource === undefined) { return []; }
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.binsData);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);
		return this.dataSource;
	}

	sortData(sort: Sort) {
		let isCollected2 = [];
		let isSelected = [];
		let isMissed = [];
		let isNotSelected = [];
		let isNotSelected_old = [];

		const data = this.binsData.slice();
		if (!sort.active || sort.direction === '') {
			this.binsData = data;
			return;
		}
		this.binsData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});

		if(this.binsData.length > 0 && sort.active == "collected"){			
			_.each(this.binsData, (item) => {
				item["symbol"] = this.getImg(item);
				if(item["symbol"] == "assets/images/dashboard/collected2.svg"){
					isCollected2.push(item);
				}else if(item["symbol"] == "assets/images/dashboard/selected.svg"){
					isSelected.push(item);
				}else if(item["symbol"] == "assets/images/dashboard/missed.svg"){
					isMissed.push(item);
				}else if(item["symbol"] == "assets/images/dashboard/notSelected.svg"){
					isNotSelected.push(item);
				}else if(item["symbol"] == "assets/images/dashboard/notSelected-old.svg"){
					isNotSelected_old.push(item);
				}
			});
			this.binsData = [];
			
			if(sort.direction === 'asc'){
				this.binsData = [...isSelected, ...isNotSelected_old, ...isMissed, ...isNotSelected, ...isCollected2];
			}else{
				this.binsData = [...isCollected2, ...isNotSelected, ...isMissed, ...isNotSelected_old,...isSelected];
			} 
		}
	}

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}
