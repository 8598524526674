@if (!isMobile) {
  <div class="create-workplan">
    <div class='gray-background' [ngClass]="{'popup-open': showWorkplanPopupApproval}" (click)="closeModal()"></div>
    <app-workplans-popup-approval [hidden]='!showWorkplanPopupApproval' (approveCancelChangesEmitter)="approveCancelWorkplanChanges($event)"></app-workplans-popup-approval>
    <div class='create-new-workplan-modal-wrapper'>
      <div class='main-title'>{{ 'NEW_WORKPLAN.SCHEDULE_WORKPLAN' | translate }}</div>
      <div class='new-workplan-data-wrapper'>
        <div class='subsites-wrapper wrapper' rtlDiv>
          <div class='workplan-subtitle' rtlDiv>{{ 'NEW_WORKPLAN.SITES' | translate }}</div>
          <mat-form-field class='workplan-input workplan-drop-down' rtlDiv>
            <mat-label [hidden]="selectedSites != null && selectedSites.length != 0" class="placeholder">{{ 'NEW_WORKPLAN.CHOOSE_SITE' | translate }}</mat-label>
            <mat-select #siteMatSelect class="select-options" name="site" [(ngModel)]="selectedSites" multiple #siteSelect="ngModel" [disabled]="sitesListData != null && sitesListData.length == 1" rtlDiv>
              @for (item of sitesListData; track item) {
                <mat-option class="no-checkbox list-item" [value]="item" (click)="selectAllSites(siteSelect,sitesListData,item)"
                  [ngClass]="{'choose-all-title': itemIsChooseAll(item)}" rtlDiv>
                  <span rtlDiv class="dflex">{{item.site_name}}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <div class="error-container">
            <div class='time-invalid-text' [hidden]='!this.sitesErrorFlag'>{{ 'NEW_WORKPLAN.SITE_ERROR' | translate }}</div>
          </div>
        </div>
        <div class='truck-wrapper wrapper' rtlDiv>
          <div class='workplan-subtitle'>{{ 'NEW_WORKPLAN.TRUCKS' | translate }}</div>
          <mat-form-field class='workplan-input workplan-drop-down-trucks' rtlDiv>
            <mat-label [hidden]="selectedTrucks != null && selectedTrucks.length != 0" class="placeholder">{{ 'NEW_WORKPLAN.CHOOSE_TRUCK' | translate }}</mat-label>
            <mat-select class="select-options" #truckMatSelect name="collection" [(ngModel)]="selectedTrucks" multiple #truckSelect="ngModel" [disabled]="selectedSites != null && trucksListDataToShow != null && (selectedSites.length == 0 || trucksListDataToShow.length == 1)" (click)="checkTruckValidity()">
              @for (item of trucksListDataToShow; track item) {
                <mat-option class="list-item" rtlDiv [value]="item" (click)="updateTruckFlag(truckSelect, trucksListDataToShow, item)">
                  @if (!item.isAll) {
                    <div rtlDiv class="dflex">
                      {{ 'NEW_WORKPLAN.TRUCK' | translate }}_{{item.tablet_id}}
                    </div>
                  } @else {
                    <ng-template [ngTemplateOutlet]="selectAllTrucksPc"></ng-template>
                  }
                  <ng-template #selectAllTrucksPc>
                    <span rtlDiv class="dflex">Select All Trucks</span>
                  </ng-template>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <div class='time-invalid-text' [hidden]='!trucksErrorFlag'>{{ 'NEW_WORKPLAN.TRUCK_ERROR' | translate }}</div>
          <div class='time-invalid-text' [hidden]='!missingSiteSelection'>{{ 'NEW_WORKPLAN.MISSING_SITE_SELECTION_ERROR' | translate }}</div>
        </div>
        <div class='daytime-wrapper wrapper dflex' rtlDiv>
          <mat-card class="card-date">
            <mat-card-content>
              <div class='workplan-subtitle'>{{ 'NEW_WORKPLAN.DATE' | translate }}</div>
              <div class='datepicker-wrapper' rtlDiv>
                <div class='selected-dates' (click)='showHideDatePicker()'>
                  <img class='calendar-icon' src="../assets/images/reports/calendar.svg" alt="calendar" rtlDiv>
                  <div class='dates-range datepicker-rtl' rtlDiv>
                    {{dateLbl}}
                  </div>
                  <img class='dropdown-icon' src="assets/images/dashboard/dropdown_arrow.svg" alt="dropdown_arrow">
                </div>
                <div class="datepicker-rtl">
                  <app-datepicker-custom [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-custom>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <div class='time-wrapper' rtlDiv>
            <div class='workplan-subtitle'>{{ 'NEW_WORKPLAN.TIME' | translate }}</div>
            <div class='time-btns-container' rtlDiv>
              <div class='time-btn left'>
                {{selectedWorkplanTime.hour}}
                <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                @if (!closeHour) {
                  <div [hidden]='!showHideDropdownLists.timeHour' class='time-dropdown-container'>
                    @for (item of workplanTime.hours; track item) {
                      <div class="drop-down-list-item time-list" (click)="selectWorkplanTime(item, 'hour')">
                        {{item}}
                      </div>
                    }
                  </div>
                }
              </div>
              <div class='time-icon'>:</div>
              <div class='time-btn right'>
                {{selectedWorkplanTime.minute}}
                <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                @if (!closeMinute) {
                  <div [hidden]='!showHideDropdownLists.timeMinute' class='time-dropdown-container'>
                    @for (item of workplanTime.minutes; track item) {
                      <div class="drop-down-list-item time-list" (click)="selectWorkplanTime(item, 'minute')">
                        {{item}}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
            <div class='time-invalid-text' [hidden]='!showTimeErrorText' rtlDiv>{{ 'NEW_WORKPLAN.INVALID_TIME_ERROR' | translate }}</div>
          </div>
        </div>
        <div class='daytime-wrapper-every wrapper dflex' rtlDiv>
          <div class='date-wrapper' rtlDiv>
            <div class='workplan-subtitle maxContent'>{{ 'NEW_WORKPLAN.REPEAT' | translate }}</div>
            <input [(ngModel)]="repeatNumber" class='date-input' type="number" min="0" id="repeat_number"/>
          </div>
          <div class='time-wrapper' rtlDiv>
            <div class='workplan-subtitle' style="visibility: hidden;">d/w/m</div>
            <mat-form-field class='repeat-Period workplan-input' rtlDiv>
              <mat-select placeholder="" class="optionTxt" (valueChange)="changeValue($event)" [(value)]="data.periods[1].value">
                @for (period of data.periods; track period) {
                  <mat-option class="list-item" [value]="period.value">
                    <span class="dflex" rtlDiv>{{period.viewValue}}</span>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class='time-wrapper' rtlDiv>
            <div class='workplan-subtitle'>{{ 'NEW_WORKPLAN.REPEAT' | translate }}</div>
            <mat-form-field class='repeat-Period workplan-input' rtlDiv>
              <mat-select [(value)]="data.days[0].value" class="optionTxt" rtlDiv>
                @for (day of data.days; track day) {
                  <mat-option class="list-item" [value]="day.value">
                    <span class="dflex" rtlDiv>{{day.viewValue}}</span>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class='main-btns-wrapper' rtlDiv>
          <div class='btns-container' rtlDiv>
            <div class='cancel-btn' (click)="closeModal()" rtlDiv>{{ 'NEW_WORKPLAN.CANCEL' | translate }}</div>
            <div class='apply-btn' (click)='saveWorkplan()' rtlDiv>{{ 'NEW_WORKPLAN.APPLY_WORKPLAN' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div class="create-workplan mobile-create-workplan">
    <app-workplans-popup-approval [hidden]='!showWorkplanPopupApproval' (approveCancelChangesEmitter)="approveCancelWorkplanChanges($event)"></app-workplans-popup-approval>
    <div class="mobile-modal-title" rtlDiv>
      <div class="dflex" (click)="closeModal()">
        <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
      </div>
      <div>
        <span class="main-title">{{ 'NEW_WORKPLAN.SCHEDULE_WORKPLAN' | translate }}</span>
      </div>
    </div>
    <div class="mobile-popup-container">
      <div class="mobile-prop-frame">
        <div class="days-between-container">
          <div class="add-ticket-form">
            <div class="events-type-container">
              <div class="grid-view" rtlDiv>
                <div class='mobile-sub-title' rtlDiv>{{ 'NEW_WORKPLAN.SITES' | translate }}</div>
                <mat-form-field class="event-type-input event-types" rtlDiv>
                  <mat-label rtlDiv [hidden]="selectedSites != null && selectedSites.length != 0" class="placeholder">{{ 'NEW_WORKPLAN.CHOOSE_SITE' | translate }}</mat-label>
                  <mat-select panelClass="mySitesClass-mobile" disableOptionCentering #siteMatSelect name="site" [(ngModel)]="selectedSites" multiple #siteSelect="ngModel" [disabled]="sitesListData != null && sitesListData.length == 1" rtlDiv>
                    @for (item of sitesListData; track item) {
                      <mat-option class="no-checkbox" [value]="item" (click)="selectAllSites(siteSelect,sitesListData,item)" rtlDiv>
                        <span rtlDiv class="dflex">{{item.site_name}}</span>
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <div class="mobile-error-container">
                  <div class='time-invalid-text' [hidden]='!this.sitesErrorFlag'>{{ 'NEW_WORKPLAN.SITE_ERROR' | translate }}</div>
                </div>
              </div>
              <div class="grid-view" rtlDiv>
                <div class='mobile-sub-title'>{{ 'NEW_WORKPLAN.TRUCKS' | translate }}</div>
                <mat-form-field class="event-type-input event-types" rtlDiv>
                  <mat-label [hidden]="selectedTrucks != null && selectedTrucks.length != 0" class="placeholder">{{ 'NEW_WORKPLAN.CHOOSE_TRUCK' | translate }}</mat-label>
                  <mat-select panelClass="mySitesClass-mobile" disableOptionCentering #truckMatSelect name="collection" [(ngModel)]="selectedTrucks" multiple #truckSelect="ngModel" [disabled]="selectedSites != null && trucksListDataToShow != null && (selectedSites.length == 0 || trucksListDataToShow.length == 1)" (click)="checkTruckValidity()">
                    @for (item of trucksListDataToShow; track item) {
                      <mat-option rtlDiv [value]="item" (click)="updateTruckFlag(truckSelect, trucksListDataToShow, item)">
                        @if (!item.isAll) {
                          <div rtlDiv class="dflex">
                            {{ 'NEW_WORKPLAN.TRUCK' | translate }}_{{item.tablet_id}}
                          </div>
                        } @else {
                          <ng-template [ngTemplateOutlet]="selectAllTrucksMobile"></ng-template>
                        }
                        <ng-template #selectAllTrucksMobile>
                          <span rtlDiv class="dflex">Select All Trucks</span>
                        </ng-template>
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <div class='time-invalid-text' [hidden]='!trucksErrorFlag'>{{ 'NEW_WORKPLAN.TRUCK_ERROR' | translate }}</div>
                <div class='time-invalid-text' [hidden]='!missingSiteSelection'>{{ 'NEW_WORKPLAN.MISSING_SITE_SELECTION_ERROR' | translate }}</div>
              </div>
              <div class='daytime-wrapper wrapper dflex' rtlDiv>
                <mat-card class="card-date">
                  <mat-card-content class="mat-card-content-date">
                    <div class='mobile-sub-title'>{{ 'NEW_WORKPLAN.DATE' | translate }}</div>
                    <div class='datepicker-wrapper' rtlDiv>
                      <div class='selected-dates' (click)='showHideDatePicker()'>
                        <div class='mobile-dates-range datepicker-rtl' rtlDiv>
                          {{dateLbl}}
                        </div>
                        <img class='dropdown-icon' src="assets/images/dashboard/dropdown_arrow.svg" alt="dropdown_arrow">
                      </div>
                      <div class="datepicker-rtl">
                        <app-datepicker-custom [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-custom>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
                <div class='time-wrapper period-list' rtlDiv>
                  <div class='mobile-sub-title'>{{ 'NEW_WORKPLAN.TIME' | translate }}</div>
                  <div class='time-btns-container' rtlDiv>
                    <div class='time-btn left'>
                      {{selectedWorkplanTime.hour}}
                      @if (!closeHour) {
                        <div [hidden]='!showHideDropdownLists.timeHour' class='mobile-time-dropdown-container'>
                          @for (item of workplanTime.hours; track item) {
                            <div class="drop-down-list-item time-list" (click)="selectWorkplanTime(item, 'hour')">
                              {{item}}
                            </div>
                          }
                        </div>
                      }
                    </div>
                    <div class='time-icon'>:</div>
                    <div class='time-btn right'>
                      {{selectedWorkplanTime.minute}}
                      @if (!closeMinute) {
                        <div [hidden]='!showHideDropdownLists.timeMinute' class='mobile-time-dropdown-container'>
                          @for (item of workplanTime.minutes; track item) {
                            <div class="drop-down-list-item time-list" (click)="selectWorkplanTime(item, 'minute')">
                              {{item}}
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                  <div class='time-invalid-text' [hidden]='!showTimeErrorText' rtlDiv>{{ 'NEW_WORKPLAN.INVALID_TIME_ERROR' | translate }}</div>
                </div>
              </div>
              <div class='mobile-daytime-wrapper-every wrapper flex-gap' rtlDiv>
                <div class='mobile-date-wrapper period-list' rtlDiv>
                  <div class='mobile-sub-title'>{{ 'NEW_WORKPLAN.REPEAT' | translate }}</div>
                  <input [(ngModel)]="repeatNumber" class='mobile-date-input' type="number" min="0" id="repeat_number"/>
                </div>
                <div class='time-wrapper grid-view' rtlDiv>
                  <div class='mobile-sub-title' style="visibility: hidden;">d/w/m</div>
                  <mat-form-field class="event-types" rtlDiv>
                    <mat-select disableOptionCentering placeholder="" (valueChange)="changeValue($event)" [(value)]="data.periods[1].value">
                      @for (period of data.periods; track period) {
                        <mat-option [value]="period.value">
                          <span class="dflex" rtlDiv>{{period.viewValue}}</span>
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class='time-wrapper' rtlDiv>
                <div class='mobile-sub-title'>{{ 'NEW_WORKPLAN.REPEAT' | translate }}</div>
                <div class="dflex-days">
                  @for (item of weekDays; track item) {
                    <div class="grayCircle-container" (click)="selectUnselectItem(item)">
                      @if (item.selected) {
                        <img class="day-circle" src="assets/images/inventory/grayCircle.svg" alt=""/>
                      }
                      @if (!item.selected) {
                        <img class="day-circle" src="assets/images/inventory/whiteCircle.svg" alt=""/>
                      }
                      <div [ngClass]="{'text-day': item.selected,'text-day-notSelected': !item.selected}">{{item.displayName | translate}}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='main-btns-wrapper' rtlDiv>
        <div class='btns-container' rtlDiv>
          <div class='mobile-cancel_btn' (click)="closeModal()" rtlDiv>{{ 'NEW_WORKPLAN.CANCEL' | translate }}</div>
          <div [ngClass]="{'mobile-apply-btn':selectedSites.length > 0 && selectedTrucks.length > 0,'mobile-not-apply-btn':selectedSites.length == 0 || selectedTrucks.length == 0}" (click)='saveWorkplan()' rtlDiv>{{ 'NEW_WORKPLAN.APPLY_WORKPLAN' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
}
